import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

const SelectAddress = (props) => {

  return (
    <div>
      {props?.addressData && props?.addressData?.length !== 0 ? (
        props?.addressData?.map((item, index) => {
          return (
            <label key={index} className="fancy-radio">
              <input
                name="address_id"
                required=""
                type="radio"
                value={item?.id}
                data-address={`${item?.address}, ${item?.city} - ${item?.pin_code}, ${item?.state}, ${item?.country}`}
                checked={parseInt(props?.state?.id) === parseInt(item?.id)}
                onChange={(e) =>
                  props?.setState({
                    ...props?.state,
                    id: e?.target?.value,
                    data: e?.target?.getAttribute("data-address"),
                  })
                }
              />
              <span>
                <i></i>
                &nbsp;
                <span style={{ fontWeight: "bold" }}>
                  {item?.address}, {item?.city} - {item?.pin_code},{" "}
                  {item?.state}, {item?.country}
                </span>
              </span>
            </label>
          );
        })
      ) : (
        <p>Address Not Found</p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state?.loginReducer?.token,
  inquiry_id: state?.setOrderReducer?.inquiry_id,
});

export default connect(mapStateToProps)(SelectAddress);
