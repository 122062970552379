import React from "react";
import { Toast } from "react-bootstrap";

export const ShowNotification = ({
  title,
  show,
  type = "info",
  position = "center",
}) => {
  return (
    <Toast
      id="toast-container"
      show={show}
      className={`toast-top-${position}`}
      autohide={true}
      delay={1000}
    >
      {/* <Toast.Header>Sky - Aakar</Toast.Header> */}
      <Toast.Body className={`toast-${type}`}>{title}</Toast.Body>
    </Toast>
  );
};
