import React from "react";

const ExistingWork = () => {
  return (
    <div className="container">
      <div className="row existing-work">
        <div className="col-md-4 content-details">
          <h2>Our Existing Work</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            egestas mauris at tincidunt venenatis. Cras vel odio ac urna ornare
            faucibus quis vel metus. Donec feugiat ligula augue, sit amet
            vulputate magna cursus in.
          </p>
        </div>
        <div className="col-md-8 work-card mt-5">
          <div className="banner-card">
            <div className="banner-card-sub"></div>
          </div>
          <div className="banner-card-small">
            <div className="banner-sub-card-small"></div>
            <div className="banner-sub-card-small"></div>
            <div className="banner-sub-card-small"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExistingWork;
