import React, { useState } from "react";
import { Rating } from "react-simple-star-rating";
import "./star.scss";

const StarRating = () => {
  const [rating, setRating] = useState(5);
  const handleRating = (rate) => {
    setRating(rate);
  };
  return (
    <div>
      <Rating onClick={handleRating} initialValue={rating} readonly />
    </div>
  );
};

export default StarRating;
