import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import LogoiCON from "../../../../assets/images/logo-icon.svg";
import Circle from "../../../../assets/images/Shape-img/circle.jpg";
import DCut from "../../../../assets/images/Shape-img/D_cut.jpg";
import CornerCut from "../../../../assets/images/Shape-img/corner_cut.jpg";
import Rectangle from "../../../../assets/images/Shape-img/rectangle.jpg";
import RoundCorner from "../../../../assets/images/Shape-img/round_corner.jpg";
import PageHeader from "../../../../components/PageHeader";
import {
  COLOURS,
  DIA_OPTIONS,
  MATERIAL_GRADE,
  MATERIAL_TYPE,
  MODE_OF_PAYMENT,
  ORDER_TYPE,
  SHAPE,
  SHAPE_WITH_IMAGE,
  WIDTH_OPTIONS,
} from "../../../../lib/Constants";
import { ShowNotification } from "../../../../components/ShowNotification";
import { useHistory } from "react-router-dom";
import { ApiCall } from "../../../../ActionApiCall/ApiCall";
import { onSetOrder } from "../../../../actions";

const StandardInquiry = (props) => {
  const { loadingPage, token } = props;

  const intialvalie = {
    product_type: "standard",
    order_type: 0,
    qty: "",
    material_type: "",
    material_grade: "",
    colour: "",
    shape: "",
    dimension: "",
    total_cost: "",
    remarks: "",
    refNum: "",
    rate: 0,
    direct_order: false,
  };

  const [formData, setFormData] = useState(intialvalie);
  const [toastMag, setToastMag] = useState({ show: false, msg: "", type: "" });
  const history = useHistory();
  const dispatch = useDispatch();

  const handleColourChange = (e) => {
    setFormData({
      ...formData,
      [e?.target?.name]: e?.target?.value,
    });

    if (typeof formData?.dimension === "object") {
      const price = Object.values(formData?.dimension).reduce(
        (accumulator, currentValue) =>
          parseFloat(currentValue) * parseFloat(accumulator)
      );
      const priceadd =
        e?.target?.value === "white" || e?.target?.value === "black"
          ? price * 0.00032
          : price * 0.00036;

      setFormData((prev) => ({
        ...prev,
        rate: priceadd.toFixed(2),
      }));

      if (formData?.qty !== "") {
        setFormData((prev) => ({
          ...prev,
          total_cost: (parseFloat(priceadd) * parseInt(formData?.qty)).toFixed(
            2
          ),
        }));
      }
    }
  };

  const handleQtyChange = (e) => {
    setFormData({
      ...formData,
      [e?.target?.name]: e?.target?.value,
      total_cost: parseFloat(formData?.rate) * parseInt(e?.target?.value),
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const req = { ...formData, dimension: JSON.stringify(formData?.dimension) };
    console.log(req);
    ApiCall(req, "user/products", false, props?.token, (result) => {
      if (result?.data?.success) {
        setToastMag({
          ...toastMag,
          show: true,
          msg: result?.data?.message ? result?.data?.message : "Successfully",
          type: "success",
        });
        if (result?.data?.inquiry_id) {
          dispatch(onSetOrder({ inquiry_id: result?.data?.inquiry_id }));
        }
        setTimeout(() => {
          if (req?.direct_order) {
            history.push("/user/process-order");
          } else {
            history.push("/user/ongoing-inquiry");
          }
        }, 500);
      } else {
        setToastMag({
          ...toastMag,
          show: true,
          msg: result?.data?.message
            ? result?.data?.message
            : "Something Wrong",
          type: "error",
        });
      }
    });
  };

  // const handlePlaceOrder = () => {
  //   const req = { ...formData, dimension: JSON.stringify(formData?.dimension), direct_order: true };
  //   dispatch(onLoggedin(req));
  // }

  useEffect(() => {
    if (formData?.shape === "Round") {
      formData?.dimension?.length && delete formData?.dimension?.length;
      formData?.dimension?.width && delete formData?.dimension?.width;
      formData?.dimension?.radius && delete formData?.dimension?.radius;
    } else {
      if (formData?.shape === "Rectangle") {
        formData?.dimension?.diameter && delete formData?.dimension?.diameter;
        formData?.dimension?.radius && delete formData?.dimension?.radius;
      } else {
        formData?.dimension?.diameter && delete formData?.dimension?.diameter;
      }
    }
  }, [formData?.shape]);

  useEffect(() => {
    if (toastMag?.show) {
      setTimeout(() => {
        setToastMag({ ...toastMag, show: false });
      }, 3000);
    }
  }, [toastMag?.show]);

  if (loadingPage) {
    return (
      <div className="page-loader-wrapper">
        <div className="loader">
          <div className="m-t-30">
            <img src={LogoiCON} width="48" height="48" alt="Lucid" />
          </div>
          <p>Please wait...</p>
        </div>
      </div>
    );
  }

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          {toastMag.show && (
            <ShowNotification
              title={toastMag?.msg}
              show={toastMag?.show}
              type={toastMag?.type}
            />
          )}
          <PageHeader
            HeaderText="New Inquiry - Standard Shape Custom Size"
            Breadcrumb={[{ name: "New Inquiry - Standard Shape Custom Size" }]}
          />
          {/* <div className="card"> */}
          <form
            onSubmit={(e) => handleFormSubmit(e)}
            method="post"
            encType="multipart/form-data"
          >
            <div className="row">
              <div className="col-lg-9">
                <div className="">
                  <div className="">
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <div className="form-group mb-3">
                          <label>Design Reference Number</label>
                          <input
                            required
                            type="text"
                            name="refNum"
                            className="form-control"
                            value={formData?.refNum}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                [e?.target?.name]: e?.target?.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <label>Select Shape From Below</label>
                          <div className="row">
                            {SHAPE_WITH_IMAGE?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="col-lg-2 col-md-3 col-sm-6 col-6 mb-2"
                                >
                                  <img
                                    style={{
                                      cursor: "pointer",
                                      borderStyle:
                                        item?.label === formData?.shape
                                          ? "outset"
                                          : "none",
                                      borderColor:
                                        item?.label === formData?.shape &&
                                        "#49c5b6",
                                    }}
                                    src={item?.imageSrc}
                                    width={130}
                                    height={130}
                                    alt={item?.label}
                                    onClick={(e) =>
                                      setFormData({
                                        ...formData,
                                        shape: item?.label,
                                      })
                                    }
                                  />
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>

                      {formData?.shape !== "" && (
                        <div className="col-md-12">
                          <div className="">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  {formData?.shape === "Round" && (
                                    <div className="col-md-3 col-sm-6">
                                      <div className="form-group mb-3">
                                        <label>Diameter (In mm)</label>
                                        <select
                                          required
                                          className="form-control"
                                          value={formData?.dimension?.diameter}
                                          name="diameter"
                                          onChange={(e) =>
                                            setFormData({
                                              ...formData,
                                              dimension: {
                                                ...formData?.dimension,
                                                [e?.target?.name]:
                                                  e?.target?.value,
                                              },
                                            })
                                          }
                                        >
                                          <option value="">Select..</option>
                                          {DIA_OPTIONS?.map((item, index) => {
                                            return (
                                              <option key={index} value={item}>
                                                {item}mm
                                              </option>
                                            );
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  )}
                                  {formData?.shape !== "Round" && (
                                    <>
                                      <div className="col-md-3 col-sm-6">
                                        <div className="form-group mb-3">
                                          <label>Width (In mm)</label>
                                          <select
                                            required
                                            className="form-control"
                                            value={formData?.dimension?.width}
                                            name="width"
                                            onChange={(e) =>
                                              setFormData({
                                                ...formData,
                                                dimension: {
                                                  ...formData?.dimension,
                                                  [e?.target?.name]:
                                                    e?.target?.value,
                                                },
                                              })
                                            }
                                          >
                                            <option value="">Select..</option>
                                            {WIDTH_OPTIONS?.map(
                                              (item, index) => {
                                                return (
                                                  <option
                                                    key={index}
                                                    value={item}
                                                  >
                                                    {item}mm
                                                  </option>
                                                );
                                              }
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-3 col-sm-6">
                                        <div className="form-group mb-3">
                                          <label>Length (In mm)</label>
                                          <input
                                            required
                                            type="number"
                                            name="length"
                                            className="form-control"
                                            min={10}
                                            max={100}
                                            onChange={(e) =>
                                              setFormData({
                                                ...formData,
                                                dimension: {
                                                  ...formData?.dimension,
                                                  [e?.target?.name]:
                                                    e?.target?.value,
                                                },
                                              })
                                            }
                                          />
                                        </div>
                                      </div>

                                      {formData?.shape !== "Rectangle" && (
                                        <div className="col-md-3 col-sm-6">
                                          <div className="form-group mb-3">
                                            <label>Radius (In mm)</label>
                                            <select
                                              required
                                              className="form-control"
                                              value={
                                                formData?.dimension?.radius
                                              }
                                              name="radius"
                                              onChange={(e) =>
                                                setFormData({
                                                  ...formData,
                                                  dimension: {
                                                    ...formData?.dimension,
                                                    [e?.target?.name]:
                                                      e?.target?.value,
                                                  },
                                                })
                                              }
                                            >
                                              <option value="">Select..</option>
                                              {DIA_OPTIONS?.map(
                                                (item, index) => {
                                                  return (
                                                    <option
                                                      key={index}
                                                      value={item}
                                                    >
                                                      {item}mm
                                                    </option>
                                                  );
                                                }
                                              )}
                                            </select>
                                          </div>
                                        </div>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="col-12 mb-3">
                        <hr
                          style={{
                            width: "100%",
                            border: "0.1px solid rgba(0,0,0,0.6)",
                            boxShadow: "0px 4px 2px rgba(0,0,0,0.3)",
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label>Material Type</label>
                          <br />
                          {MATERIAL_TYPE?.map((item, index) => {
                            return (
                              <label key={index} className="fancy-radio">
                                <input
                                  name="material_type"
                                  required=""
                                  type="radio"
                                  value={item?.toLowerCase()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      [e?.target?.name]: e?.target?.value,
                                    })
                                  }
                                />
                                <span>
                                  <i></i>
                                  {item}
                                </span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label>Material Grade</label>
                          <br />
                          {MATERIAL_GRADE?.map((item, index) => {
                            return (
                              <label key={index} className="fancy-radio">
                                <input
                                  name="material_grade"
                                  required=""
                                  type="radio"
                                  value={item?.toLowerCase()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      [e?.target?.name]: e?.target?.value,
                                    })
                                  }
                                />
                                <span>
                                  <i></i>
                                  {item}
                                </span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label>Order Type</label>
                          <br />
                          {ORDER_TYPE?.map((item, index) => {
                            return (
                              <label key={index} className="fancy-radio">
                                <input
                                  name="order_type"
                                  required
                                  type="radio"
                                  value={item?.value}
                                  checked={
                                    parseInt(formData?.order_type) ===
                                    item?.value
                                  }
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      [e?.target?.name]: e?.target?.value,
                                    })
                                  }
                                />
                                <span>
                                  <i></i>
                                  {item?.label}
                                </span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label>Colour</label>
                          <div className="input-group mb-2">
                            <select
                              required
                              className="form-control"
                              value={formData?.colour}
                              name="colour"
                              onChange={(e) => handleColourChange(e)}
                            >
                              <option value="">Select..</option>
                              {COLOURS?.map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={item?.toLowerCase()}
                                  >
                                    {item}
                                  </option>
                                );
                              })}
                            </select>
                            <div
                              className="input-group-prepend"
                              style={{ width: "20%" }}
                            >
                              <div
                                className="input-group-text"
                                style={{
                                  background: formData?.colour,
                                  width: "100%",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-3">
                          <label>Quantity</label>
                          <input
                            required
                            type="number"
                            name="qty"
                            className="form-control"
                            value={formData?.qty}
                            onChange={(e) => handleQtyChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div
                  className="card"
                  style={{
                    width: "95%",
                    marginLeft: "1em",
                    boxShadow: "-15px 14px 0px #122E4D",
                  }}
                >
                  <div className="body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <label>Rate</label>
                          <input
                            required
                            disabled
                            type="number"
                            name="rate"
                            className="form-control"
                            value={formData?.rate}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <label>Total Cost</label>
                          <input
                            required
                            disabled
                            type="number"
                            name="total_cost"
                            className="form-control"
                            value={formData?.total_cost}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <label>Note for Manufacturer</label>
                          <textarea
                            name="remarks"
                            className="form-control"
                            rows="4"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                [e?.target?.name]: e?.target?.value,
                              })
                            }
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <input
                            style={{
                              width: "100%",
                              background: "#19334F",
                              color: "white",
                            }}
                            type="submit"
                            className="btn"
                            value={"Save & Submit Inquiry"}
                            name="save_inquiry"
                            onClick={() =>
                              setFormData({ ...formData, direct_order: false })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <input
                            style={{ width: "100%" }}
                            type="submit"
                            className="btn btn-success"
                            value={"Proceed to Order (Pay Now)"}
                            name="direct_order"
                            onClick={() =>
                              setFormData({ ...formData, direct_order: true })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loadingPage: state.analyticalReducer.loadingPage,
  token: state?.loginReducer?.token,
});

export default connect(mapStateToProps)(StandardInquiry);
