const initialState = {
  inquiry_id: null,
  order_id: null,
  orderdata: null,
};

const setOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ON_SETORDER": {
      return {
        ...state,
        inquiry_id: action?.inquiry_id,
        order_id: action?.order_id,
        orderdata: action?.orderdata,
      };
    }
    default:
      return state;
  }
};

export default setOrderReducer;
