import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Redirect, Route } from "react-router-dom";

const AdminAuthmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) => (
  <Route
    {...rest}
    exact
    render={(props) => {
      if (isAuthProtected && !localStorage.getItem("data")) {
        return (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        );
      }

      return (
        <>
          <Layout />
          <div id="main-content">
            <Component {...props} />
          </div>
        </>
      );
    }}
  />
);

export default AdminAuthmiddleware;
