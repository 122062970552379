import AdminDashbord from "./screens/Admin/Dashboard/AdminDashbord";
import NewRequest from "./screens/Admin/AdminUsers/NewRequest";
import Approved from "./screens/Admin/AdminUsers/Approved";
import UnApproved from "./screens/Admin/AdminUsers/UnApproved";
import UserDashbord from "./screens/User/Dashboard/UserDashbord";

import NewInquiry from "./screens/Admin/AdminInquiry/NewInquiry";
import UnapproveInquiry from "./screens/Admin/AdminInquiry/UnapproveInquiry";
import UserRejectedInquiry from "./screens/User/Inquiry/UserRejectedInquiry";
import UserCompleted from "./screens/User/Order/UserCompleted";
import UserProcessing from "./screens/User/Order/UserProcessing";
import AdminCompleted from "./screens/Admin/Order/AdminCompleted";
import AdminProcessing from "./screens/Admin/Order/AdminProcessing";
import UserSupport from "./screens/User/UserSupport";
import UserOngoingInquiry from "./screens/User/Inquiry/UserOngoingInquiry";
import CustomInquiry from "./screens/User/Inquiry/CustomInquiry";
import StandardInquiry from "./screens/User/Inquiry/StandardInquiry";
import UserProcessOrder from "./screens/User/UserProcessOrder";
import Profile from "./screens/User/Profile";
import UserDesign from "./screens/User/UserDesign";
import UserLibrary from "./screens/User/UserLibrary";
import AdminLibrary from "./screens/Admin/AdminLibrary";
import ThankYouPage from "./screens/User/UserProcessOrder/ThankYouPage";
import WaitingPage from "./screens/User/UserProcessOrder/WaitingPage";
import MainPage from "./screens/Home/MainPage";

const homeRoutes = [{ path: "/", component: MainPage }];

const adminRoutes = [
  { path: "/admin/dashboard", component: AdminDashbord },
  { path: "/admin/new-users", component: NewRequest },
  { path: "/admin/approve-users", component: Approved },
  { path: "/admin/unapprove-users", component: UnApproved },
  { path: "/admin/ongoing-inquiry", component: NewInquiry },
  { path: "/admin/unapprove-inquiry", component: UnapproveInquiry },
  { path: "/admin/completed-order", component: AdminCompleted },
  { path: "/admin/processing-order", component: AdminProcessing },
  { path: "/admin/design-library", component: AdminLibrary },
];

const userRoutes = [
  { path: "/user/dashboard", component: UserDashbord },
  { path: "/user/new-standard-inquiry", component: StandardInquiry },
  { path: "/user/new-custom-inquiry", component: CustomInquiry },
  { path: "/user/rejected-inquiry", component: UserRejectedInquiry },
  { path: "/user/ongoing-inquiry", component: UserOngoingInquiry },
  { path: "/user/completed-order", component: UserCompleted },
  { path: "/user/processing-order", component: UserProcessing },
  { path: "/user/support", component: UserSupport },
  { path: "/user/process-order", component: UserProcessOrder },
  { path: "/user/profile", component: Profile },
  { path: "/user/my-design", component: UserDesign },
  { path: "/user/design-library", component: UserLibrary },
  { path: "/user/order-success", component: ThankYouPage },
  { path: "/user/waiting-status", component: WaitingPage },
];

export { userRoutes, adminRoutes, homeRoutes };
