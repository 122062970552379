import React from "react";
import HomeLayout from "../HomeLayout";
import FooterComponents from "../FooterComponents";

const RefundPolicy = () => {
  return (
    <>
      <HomeLayout />
      <div className="container" style={{textAlign: "justify"}}>
        <div className="card">
          <div className="card-header">
            <h4>Return &amp; Refund Policy</h4>
          </div>
          <div className="card-body">
            <p>
              Our Return and Refund Policy was last updated 19 September 2023
            </p>
            <p>
              Thank you for shopping at{" "}
              <a href="https://skycorp.in/" target="_blank">
                www.skycorp.in
              </a>
            </p>
            <p>
              If, for any reason, You are not completely satisfied with a
              purchase We invite You to review our policy on refunds and
              returns.
            </p>
            <p>
              The following terms are applicable for any products that You
              purchased with Us.
            </p>
            <br />
            <h5>Interpretation and Definitions</h5>
            <br />
            <h6>- Interpretation</h6>
            <p>
              The words of which the initial letter is capitalized have meanings
              defined under the following conditions. The following definitions
              shall have the same meaning regardless of whether they appear in
              singular or in plural.
            </p>
            <br />
            <h6>- Definitions</h6>
            <p>For the purposes of this Return and Refund Policy:</p>
            <ul>
              <li>
                <b>“Company”</b> (referred to as either &quot;the Company&quot;,
                &quot;We&quot;, &quot;Us&quot; or &quot;Our&quot; in this
                Agreement) refers to [SKY INDUSTRIES LIMITED]
              </li>
              <br />
              <li>
                <b>“Goods”</b> refers to the items offered for sale on the
                Service.
              </li>
              <br />
              <li>
                <b>“Orders”</b> means a request by You to purchase Goods from
                Us.
              </li>
              <br />
              <li>
                <b>“Service”</b> refers to the Website.
              </li>
              <br />
              <li>
                <b>“Website”</b> refers to skycorp.in, accessible from{" "}
                <a href="https://skycorp.in/" target="_blank">
                  www.skycorp.in
                </a>
              </li>
              <br />
              <li>
                <b>“You”</b> means the individual accessing or using the
                Service, or the company, or other legal entity on behalf of
                which such individual is accessing or using the Service, as
                applicable.
              </li>
              <br />
            </ul>
            <h5>Your Order Cancellation Rights</h5>
            <br />
            <p>
              You are entitled to cancel Your Order within 14 days without
              giving any reason for doing so.
            </p>
            <p>
              The deadline for cancelling an Order is 14 days from the date on
              which You received the Goods or on which a third party you have
              appointed, who is not the carrier, takes possession of the product
              delivered.
            </p>
            <p>
              In order to exercise Your right of cancellation, You must inform
              Us of your decision by means of a clear statement. You can inform
              us of your decision by:
            </p>
            <ul>
              <li>
                By visiting this page on our website:{" "}
                <a href="https://skycorp.in/refund" target="_blank">
                  www.skycorp.in/refund
                </a>
              </li>
              <br />
              <li>
                By sending us an email:{" "}
                <a href="mailto: refund@skycorp.in">refund@skycorp.in</a>
              </li>
              <br />
            </ul>
            <p>
              We will reimburse You no later than 14 days from the day on which
              We receive the returned Goods. We will use the same means of
              payment as You used for the Order, and You will not incur any fees
              for such reimbursement.
            </p>
            <br />
            <h5>Conditions for Returns</h5>
            <br />
            <p>
              In order for the Goods to be eligible for a return, please make
              sure that:
            </p>
            <br />
            <ul>
              <li>The Goods were purchased in the last 14 days</li>
              <br />
              <li>The Goods are in the original packaging</li>
              <br />
              <li>
                The following Goods cannot be returned:
                <ul>
                  <br />

                  <li>
                    The supply of Goods made to Your specifications or clearly
                    personalized.
                  </li>
                  <br />
                  <li>
                    The supply of Goods which according to their nature are not
                    suitable to be returned, deteriorate rapidly or where the
                    date of expiry is over.
                  </li>
                  <br />
                  <li>
                    The supply of Goods which are not suitable for return due to
                    health protection or hygiene reasons and were unsealed after
                    delivery.
                  </li>
                  <br />
                  <li>
                    The supply of Goods which are, after delivery, according to
                    their nature, inseparably mixed with other items.
                  </li>
                  <br />
                </ul>
              </li>
              <br />
            </ul>
            <p>
              We reserve the right to refuse returns of any merchandise that
              does not meet the above return conditions in our sole discretion.
            </p>
            <br />
            <h5>Returning Goods</h5>
            <br />
            <p>
              You are responsible for the cost and risk of returning the Goods
              to Us. You should send the Goods at the following address:
            </p>
            <br />
            <div>
              <p>
                <b>
                  Sky Industries Limited,
                  <br />
                  1101, Universal Majestic,
                  <br />
                  Ghatkopar Mankhurd Link Road,
                  <br />
                  Chembur – West
                  <br />
                  Mumbai - 400043
                </b>
              </p>
            </div>
            <br />
            <p>
              We cannot be held responsible for Goods damaged or lost in return
              shipment. Therefore, We recommend an insured and trackable mail
              service. We are unable to issue a refund without actual receipt of
              the Goods or proof of received return delivery.
            </p>
            <br />
            <h5>Contact Us</h5>
            <br />
            <p>
              If you have any questions about our Returns and Refunds Policy,
              please contact us:
            </p>
            <ul>
              <li>
                By visiting this page on our website:{" "}
                <a href="https://skycorp.in/contact_us" target="_blank">
                  www.skycorp.in/contact_us
                </a>{" "}
              </li>
              <br />
              <li>
                By sending us an email:{" "}
                <a href="mailto: contact@skycorp.in">contact@skycorp.in</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <FooterComponents />
    </>
  );
};

export default RefundPolicy;
