import React, { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader";
import { useDispatch, connect } from "react-redux";
import { ApiCallGet } from "../../../ActionApiCall/ApiCall";
import { capitaliseFirstLetter } from "@fullcalendar/core";
import { CustomRoute } from "../../../lib/ApiRoute";

const AdminLibrary = (props) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchInquiryData = () => {
    setLoading(true);
    ApiCallGet("admin/shape-library", props?.token, (result) => {
      if (result?.data?.success) {
        setData(result?.data?.data);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchInquiryData();
  }, []);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText="Design Library"
            Breadcrumb={[{ name: "Design Library" }]}
          />
          <div className="col-lg-12">
            <div className="card">
              <div className="body table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      {/* <th>Inquiry Date</th> */}
                      {/* <th>Order Date</th> */}
                      <th>Design Ref Num</th>
                      <th>Type</th>
                      <th>Shape / File</th>
                      <th>Nearest Shape</th>
                      <th>Dimension</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={11} className="text-center">
                          Loading.....
                        </td>
                      </tr>
                    ) : data && data?.length > 0 ? (
                      data?.map((item, index) => {
                        var dimension = JSON.parse(item?.dimension);
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.user_ref_num}</td>
                            <td>{capitaliseFirstLetter(item?.product_type)}</td>
                            <td style={{ maxWidth: "140px", textWrap: "wrap" }}>
                              {item?.images === null ? (
                                item?.shape
                              ) : (
                                <a
                                  target="_blank"
                                  href={CustomRoute + item?.images}
                                  className="btn btn-sm btn-success"
                                >
                                  View
                                </a>
                              )}
                            </td>
                            <td>
                              {item?.images !== null &&
                                capitaliseFirstLetter(item?.shape)}
                            </td>
                            <td>
                              {item?.dimension !== null ? (
                                Object.entries(JSON.parse(item?.dimension)).map(
                                  ([key, value]) => (
                                    <div key={key}>
                                      <span>{key}</span>: <span>{value}mm</span>
                                    </div>
                                  )
                                )
                              ) : (
                                <p style={{ color: "red" }}>Not Applicable</p>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={11} className="text-center">
                          Not Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state?.loginReducer?.token,
});

export default connect(mapStateToProps)(AdminLibrary);
