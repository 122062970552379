import React from "react";

const KnowMore = () => {
  return (
    <div className="container mb-5">
      <div className="know-more">
        <div className="row">
          <div className="col-8">
            <div className="content">
              <h4>We Serve Industries</h4>
            </div>
            <div className="content-details">
              <ul>
                <li>Lorem ipsum dolor sit</li>
                <li>amet, consectetur</li>
                <li>adipiscing elit.</li>
                <li>Suspendisse egestas</li>
              </ul>
              <ul>
                <li>Lorem ipsum dolor sit</li>
                <li>amet, consectetur</li>
                <li>adipiscing elit.</li>
                <li>Suspendisse egestas</li>
              </ul>
            </div>
            <div className="content-button">
              <button>Click to know more &nbsp; &nbsp; <i className="fa fa-arrow-right"></i></button>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
      </div>
    </div>
  );
};

export default KnowMore;
