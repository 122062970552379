import Joi from "joi";

const signInSchema = () =>
  Joi.object({
    email: Joi.string()
      .email({ minDomainSegments: 2, tlds: { allow: ["com", "net"] } })
      .required()
      //   .label("Username")
      .messages({
        "any.required": "Email is required",
        "string.empty": "Email is required",
        "string.base": "Invalid Email",
      }),
    password: Joi.string()
      .min(3)
      .max(15)
      .required()
      //   .label("Password")
      .messages({
        "any.required": "Password is required",
        "string.empty": "Password is required",
        "string.base": "Invalid Password",
      }),
  });
export default signInSchema;
