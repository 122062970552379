import React from "react";
import { Link } from "react-router-dom";

const ShowDetails = () => {
  return (
    <div className="container">
      <div className="row show-details">
        <div className="col-md-7 card-backgorund">
          <div className="card-content">
            <div className="main-card">
              <div className="sub-card">
                <div className="sub-card">
                  <div className="sub-card"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5 content-details">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            egestas mauris at tincidunt venenatis. Cras vel odio ac urna ornare
            faucibus quis vel metus. Donec feugiat ligula augue, sit amet
            vulputate magna cursus in.
          </p>
          <Link to={"/login"} className="button-login">
            Login In &nbsp; &nbsp; <i className="fa fa-arrow-right"></i>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ShowDetails;
