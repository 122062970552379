import React from "react";

const DownloadPage = () => {
  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-md-8 col-12 download-content">
          <h2>Hook & Loop</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            egestas mauris at tincidunt venenatis. Cras vel odio ac urna ornare
            faucibus quis vel metus. Donec feugiat ligula augue, sit amet
            vulputate magna cursus in.
          </p>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            egestas mauris at tincidunt venenatis. Cras vel odio ac urna ornare
            faucibus quis .
          </p>
          <button className="button-download">
            Download Now &nbsp; &nbsp; <i className="fa fa-arrow-down"></i>
          </button>
        </div>
        <div className="col-md-4 col-12 show-download-banner">
          <div className="show-card-1">
            <div className="show-card-2"></div>
            <div className="show-card-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadPage;
