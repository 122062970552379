import React, { useState, useEffect } from "react";
import AakarModal from "../../../components/AakarModal";
import AddNewAddress from "../../../components/AddNewAddress";
import { validate } from "../../../lib/joiHelper";
import addAddressSchema from "../../../components/AddNewAddress/addAddressSchema";
import { ApiCall, ApiCallGet } from "../../../ActionApiCall/ApiCall";
import { ShowNotification } from "../../../components/ShowNotification";
import { connect } from "react-redux";
import { ShowSwal } from "../../../components/ShowSwal";

const ProfileAddressCard = ({
  address,
  token,
  apiCallProcess,
  setApiCallProcess,
  pageLoading,
}) => {
  const fillObjAddNew = {
    token: token,
    address: "",
    city: "",
    state: "",
    country: "",
    pin_code: "",
  };
  const [updateRequest, setUpdateRequest] = useState([]);
  const [openAddAddressModal, setOpenAddAddressModal] = useState(false);
  const [openEditAddressModal, setOpenEditAddressModal] = useState(false);
  const [requestForAdd, setRequestForAdd] = useState(fillObjAddNew);
  const [requestForAddError, setRequestForAddError] = useState(fillObjAddNew);
  const [toastMag, setToastMag] = useState({ show: false, msg: "", type: "" });

  const schema = addAddressSchema();

  const handleAddAddressModal = () => {
    setOpenAddAddressModal(true);
  };

  const handleModalClose = () => {
    openAddAddressModal && setOpenAddAddressModal(false);
    openEditAddressModal && setOpenEditAddressModal(false);
  };

  const handleopenEditAddressModal = (id) => {
    var filterData = address?.filter((value) => {
      return parseInt(value.id) === parseInt(id);
    });
    setUpdateRequest(filterData[0]);
    setOpenEditAddressModal(true);
  };

  const handleAddModalConfirm = () => {
    const errorclone = validate(requestForAdd, schema);
    if (errorclone) {
      setRequestForAddError(errorclone);
    } else {
      setApiCallProcess({ ...apiCallProcess, loading: true });
      ApiCall(requestForAdd, "user/add-new-address", false, token, (res) => {
        if (res?.data?.success) {
          setToastMag({
            ...toastMag,
            show: true,
            msg: res?.data?.message ? res?.data?.message : "Successfully",
            type: "success",
          });
          setOpenAddAddressModal(false);
        } else {
          setToastMag({
            ...toastMag,
            show: true,
            msg: res?.data?.message ? res?.data?.message : "Something Wrong",
            type: "error",
          });
        }
        setApiCallProcess({
          ...apiCallProcess,
          loading: false,
          action: !apiCallProcess?.action,
        });
      });
    }
  };

  const handleDeleteModalConfirm = (id) => {
    ShowSwal(
      "Are you sure?",
      "",
      "warning",
      "Yes, Delete it!",
      "Cancel",
      (result) => {
        if (result?.isConfirmed) {
          ApiCallGet(`user/delete-address/${id}`, token, (res) => {
            if (res?.data?.success) {
              setToastMag({
                ...toastMag,
                show: true,
                msg: res?.data?.message ? res?.data?.message : "Successfully",
                type: "success",
              });
              setOpenAddAddressModal(false);
            } else {
              setToastMag({
                ...toastMag,
                show: true,
                msg: res?.data?.message
                  ? res?.data?.message
                  : "Something Wrong",
                type: "error",
              });
            }
            setApiCallProcess({
              ...apiCallProcess,
              loading: false,
              action: !apiCallProcess?.action,
            });
          });
        }
      }
    );
  };

  const handleEditModalConfirm = () => {
    const errorclone = validate(updateRequest, schema);
    if (errorclone) {
      setRequestForAddError(errorclone);
    } else {
      setApiCallProcess({ ...apiCallProcess, loading: true });
      // console.log(requestForAdd);
      ApiCall(updateRequest, "user/update-address", false, token, (res) => {
        if (res?.data?.success) {
          setToastMag({
            ...toastMag,
            show: true,
            msg: res?.data?.message ? res?.data?.message : "Successfully",
            type: "success",
          });
          setOpenEditAddressModal(false);
        } else {
          setToastMag({
            ...toastMag,
            show: true,
            msg: res?.data?.message ? res?.data?.message : "Something Wrong",
            type: "error",
          });
        }
        setApiCallProcess({
          ...apiCallProcess,
          loading: false,
          action: !apiCallProcess?.action,
        });
      });
    }
  };

  useEffect(() => {
    if (toastMag?.show) {
      setTimeout(() => {
        setToastMag({ ...toastMag, show: false });
      }, 4000);
    }
  }, [toastMag?.show]);

  return (
    <>
      {toastMag.show && (
        <ShowNotification
          title={toastMag?.msg}
          show={toastMag?.show}
          type={toastMag?.type}
        />
      )}
      <AakarModal
        show={openAddAddressModal}
        handleClose={handleModalClose}
        headerTitle="Add New Aaddress"
        body={
          <AddNewAddress
            state={requestForAdd}
            setState={setRequestForAdd}
            inputError={requestForAddError}
          />
        }
        loadState={apiCallProcess}
        handleConfirm={handleAddModalConfirm}
      />
      <AakarModal
        show={openEditAddressModal}
        handleClose={handleModalClose}
        headerTitle="Edit Aaddress"
        body={
          <AddNewAddress
            state={updateRequest}
            setState={setUpdateRequest}
            inputError={requestForAddError}
          />
        }
        loadState={apiCallProcess}
        handleConfirm={handleEditModalConfirm}
      />
      <div className="card">
        <div className="header">
          <div style={{ display: "flex", justifyContent: "flex-start" }}>
            <h2 className="pt-1">Manage Address</h2>
            <button
              className="btn btn-info btn-sm ml-4"
              onClick={handleAddAddressModal}
            >
              <i className="fa fa-pencil"></i>
            </button>
          </div>
        </div>
        <div className="body">
          {pageLoading ? (
            <h5 className="text-center">Loading...</h5>
          ) : address?.length !== 0 ? (
            address?.map((value, index) => {
              return (
                <div key={index}>
                  <hr />
                  <p>{`${value?.address}, ${value?.city} - ${value?.pin_code}, ${value?.state}, ${value?.country}`}</p>
                  <div className="text-right">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleopenEditAddressModal(value?.id)}
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDeleteModalConfirm(value?.id)}
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <h5 className="text-center">Address Not Found</h5>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileAddressCard;
