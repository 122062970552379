import Joi from "joi";

const orderSchema = () =>
  Joi.object({
    mode_of_payment: Joi.string().required().messages({
      "any.required": "Mode of Payment is required",
      "string.empty": "Mode of Payment is required",
      "string.base": "Invalid Mode of Payment",
    }),
    address_id: Joi.string().required().messages({
        "any.required": "Address is required",
        "string.empty": "Address is required",
        "string.base": "Invalid Address",
      }),
  });
export default orderSchema;
