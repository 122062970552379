import axios from "axios";
import { ApiRoute } from "../lib/ApiRoute";

const api_url = ApiRoute;

// const data = JSON.parse(localStorage.getItem("data"));

export const ApiCall = (
  requestPayload,
  url,
  bulkFile = false,
  token,
  callback
) => {
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers":
      " Origin, Content-Type, Accept, Authorization, X-Request-With",
    "Access-Control-Allow-Credentials": " true",
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${token}`,
  };

  let formData = new FormData();
  Object.entries(requestPayload).map(([key, value]) => {
    if (bulkFile) {
      if (value && typeof value === "object") {
        Object.entries(value).map(([index, item]) => {
          formData.append(key + index, item);
        });
      } else {
        formData.append(key, value);
      }
    } else {
      formData.append(key, value);
    }
  });

  axios
    .post(api_url + url, formData, {
      headers: headers,
    })
    .then((result) => {
      callback(result);
    })
    .catch((error) => {
      callback(error);
    });
};

export const ApiCallGet = (url, token, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  axios
    .get(api_url + url, {
      headers: headers,
    })
    .then((result) => {
      callback(result);
    })
    .catch((error) => {
      callback(error);
    });
};
