import Joi from "joi";

const updateSchema = () =>
  Joi.object({
    name: Joi.string().required().messages({
      "any.required": "Name is required",
      "string.empty": "Name is required",
      "string.base": "Invalid Name",
    }),
    email: Joi.string()
      .email({ minDomainSegments: 2, tlds: { allow: ["com", "net"] } })
      .required()
      .messages({
        "any.required": "Email is required",
        "string.empty": "Email is required",
        "string.base": "Invalid Email",
      }),
    gst_no: Joi.string()
      .required()
      .pattern(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9A-Z]{1}[Z]{1}[0-9]{1}$/)
      .messages({
        "string.empty": "GST number is required",
        "string.base": "GST number must be a string",
        "string.pattern.base": "Invalid GST number format",
      }),
    mobile_no: Joi.string()
      .required()
      .pattern(/^[0-9]{10}$/)
      .messages({
        "string.empty": "Mobile No. is required",
        "string.pattern.base": "Mobile number must be a 10-digit number",
        "string.base": "Invalid Mobile No.",
      }),
    company_name: Joi.string().required().messages({
      "any.required": "Company Name is required",
      "string.empty": "Company Name is required",
      "string.base": "Invalid Company Name",
    }),
  });
export default updateSchema;
