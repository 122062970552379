import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PageHeader from "../../../../components/PageHeader";
import "./thankPage.scss";
import { connect } from "react-redux";
import { ApiCallGet } from "../../../../ActionApiCall/ApiCall";
import { capitaliseFirstLetter } from "@fullcalendar/core";

const ThankYouPage = (props) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const orders_id_Params = urlParams.get("order_id");
  const payment_Params = urlParams.get("payment_failed");

  console.log(orders_id_Params);

  const fetchOrderDataById = () => {
    if (props?.order_id) {
      setLoading(true);
      ApiCallGet(
        `user/order-details/${props?.order_id}`,
        props?.token,
        (result) => {
          if (result?.data?.success) {
            setData(result?.data?.data);
          }
          setLoading(false);
        }
      );
    } else {
      if (
        orders_id_Params &&
        orders_id_Params != "" &&
        orders_id_Params != null
      ) {
        setLoading(true);
        ApiCallGet(
          `user/order-details/${orders_id_Params}`,
          props?.token,
          (result) => {
            if (result?.data?.success) {
              setData(result?.data?.data);
            }
            setLoading(false);
          }
        );
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchOrderDataById();
  }, []);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText="Order Status"
            Breadcrumb={[{ name: "Order Status" }]}
          />
          {loading ? (
            <h4 className="text-center">Loading...</h4>
          ) : data ? (
            <div className="row">
              <div
                className="col-lg-12 col-md-12 col-sm-12"
                style={{ color: "red", fontSize: "18px", textAlign: "center" }}
              >
                <p>**Don't Refresh This Page</p>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h5>Thank You for your order</h5>
                  </div>
                  <div className="card-body">
                    <table className="table table-borderless success-order-table">
                      <tr>
                        <th>Design Ref Num: </th>
                        <td>{data?.inquiry?.product?.user_ref_num}</td>
                      </tr>
                      <tr>
                        <th>Order Code: </th>
                        <td>{data?.order_code}</td>
                      </tr>
                      <tr>
                        <th>Order Total: </th>
                        <td>&#8377; {data?.inquiry?.total_price}</td>
                      </tr>
                      <tr>
                        <th>Order Date: </th>
                        <td>{data?.order_date}</td>
                      </tr>
                      <tr>
                        <th>Payment Mode: </th>
                        <td>{capitaliseFirstLetter(data?.mode_of_payment)}</td>
                      </tr>
                      <tr>
                        <th>Billing Address: </th>
                        <td>
                          {data?.address?.address}, {data?.address?.city} -{" "}
                          {data?.address?.pin_code},<br />
                          {data?.address?.state}, {data?.address?.country}
                        </td>
                      </tr>
                      <tr>
                        <th>Delivered: </th>
                        <td>
                          {parseInt(data?.status) === 0
                            ? "Pending"
                            : "Completed"}
                        </td>
                      </tr>
                    </table>
                    <Link
                      to={"processing-order"}
                      className="btn btn-success btn-sm"
                    >
                      GoTo Process Order
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                textAlign: "center",
                marginTop: "10em",
              }}
            >
              {payment_Params && payment_Params == 1 && (
                <p style={{ color: "red" }}>Payment Failed...</p>
              )}
              <p>
                Please <Link to="processing-order">Back</Link> in Ongoing Order
                and can see the order status
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state?.loginReducer?.token,
  order_id: state?.setOrderReducer?.order_id,
});

export default connect(mapStateToProps)(ThankYouPage);
