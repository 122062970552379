import Joi from "joi";

const addAddressSchema = () =>
  Joi.object({
    address: Joi.string().required().messages({
      "any.required": "Address is required",
      "string.empty": "Address is required",
      "string.base": "Invalid Address",
    }),
    pin_code: Joi.string()
      .required()
      .pattern(/^[0-9]{6}$/)
      .messages({
        "any.required": "Pin Code is required",
        "string.empty": "Pin Code is required",
        "string.pattern.base": "Pin Code must be a 6-digit number",
        "string.base": "Invalid Pin Code",
      }),
    city: Joi.string().required().messages({
      "any.required": "City is required",
      "string.empty": "City is required",
      "string.base": "Invalid City",
    }),
    state: Joi.string().required().messages({
      "any.required": "State is required",
      "string.empty": "State is required",
      "string.base": "Invalid State",
    }),
    country: Joi.string().required().messages({
      "any.required": "Country is required",
      "string.empty": "Country is required",
      "string.base": "Invalid Country",
    }),
  });
export default addAddressSchema;
