import React from "react";
import SkyMan1 from "../../../assets/images/aakar-svg/skyman_1.png";
import UpperMark from "../../../assets/images/aakar-svg/upper_mark.png";
import LowerMark from "../../../assets/images/aakar-svg/lower_mark.png";
import SkyMan2 from "../../../assets/images/aakar-svg/skyman_2.png";

const WhyUsPage = () => {
  return (
    <>
      <div className="container">
        <div className="grid-container">
          <div className="item why-img">
            <img src={SkyMan1} width={250} height={650} />
          </div>
          <div className="item upper-mark">
            <img src={UpperMark} width={130} height={100} />
          </div>
          <div className="item content">
            <h4>Why Us?</h4>
            <br />
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse egestas mauris at tincidunt venenatis. Cras vel odio
              ac urna ornare faucibus quis vel metus. Donec feugiat ligula
              augue, sit amet vulputate magna cursus in.
            </p>
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse egestas */}
            <ul>
              <li>Lorem ipsum dolor sit</li>
              <li>amet, consectetur</li>
              <li>adipiscing elit</li>
              <li>Suspendisse egestas</li>
            </ul>
            <button className="button-explore">
              Explore more &nbsp; &nbsp; <i className="fa fa-arrow-right"></i>
            </button>
          </div>
          <div className="item lower-mark">
            <img src={LowerMark} width={110} height={110} />
          </div>
        </div>
        <div className="manually-hr">
          <hr className="hr-1" />
        </div>
      </div>
    </>
  );
};

export default WhyUsPage;
