import React from "react";

const AddNewAddress = ({ state, setState, inputError }) => {
  return (
    <div>
      <div className="form-group mb-3">
        <label>Address</label>
        <textarea
          required
          type="text"
          name="address"
          className="form-control"
          defaultValue={state?.address}
          onChange={(e) =>
            setState({ ...state, [e.target.name]: e.target.value })
          }
        >
        </textarea>
        <span style={{ color: "red" }}>{inputError?.address}</span>
      </div>
      <div className="form-group mb-3">
        <label>City</label>
        <input
          required
          type="text"
          name="city"
          className="form-control"
          value={state?.city}
          onChange={(e) =>
            setState({ ...state, [e.target.name]: e.target.value })
          }
        />
        <span style={{ color: "red" }}>{inputError?.city}</span>
      </div>
      <div className="form-group mb-3">
        <label>State</label>
        <input
          required
          type="text"
          name="state"
          className="form-control"
          value={state?.state}
          onChange={(e) =>
            setState({ ...state, [e.target.name]: e.target.value })
          }
        />
        <span style={{ color: "red" }}>{inputError?.state}</span>
      </div>
      <div className="form-group mb-3">
        <label>Country</label>
        <input
          required
          type="text"
          name="country"
          className="form-control"
          value={state?.country}
          onChange={(e) =>
            setState({ ...state, [e.target.name]: e.target.value })
          }
        />
        <span style={{ color: "red" }}>{inputError?.country}</span>
      </div>
      <div className="form-group mb-3">
        <label>Pin Code</label>
        <input
          required
          type="text"
          name="pin_code"
          className="form-control"
          value={state?.pin_code}
          onChange={(e) =>
            setState({ ...state, [e.target.name]: e.target.value })
          }
        />
        <span style={{ color: "red" }}>{inputError?.pin_code}</span>
      </div>
    </div>
  );
};

export default AddNewAddress;
