import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const Access = (props) => {
  return (
    <>
      {parseInt(props.role) === 1 ? (
        <Redirect to="/admin/dashboard" />
      ) : parseInt(props.role) === 0 ? (
        <Redirect to="/user/dashboard" />
      ) : (
        <Redirect to="/login" />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  role: state?.loginReducer.role,
});

export default connect(mapStateToProps)(Access);
