import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import PageHeader from "../../../components/PageHeader";
import FollowersCard from "../../../components/Dashboard/FollowersCard";
import ProfileV2Tabs from "../../../components/Pages/ProfileV2Tabs";
import ProfileIconCard from "../../../components/Pages/ProfileIconCard";
import ProfileSliderCard from "../../../components/Pages/ProfileSliderCard";
import ProfileHeaderCard from "./ProfileHeaderCard";
import ProfileAddressCard from "./ProfileAddressCard";
import { ApiCallGet } from "../../../ActionApiCall/ApiCall";
import { logOut, onGetUser } from "../../../actions";
import ProfileDetails from "./ProfileDetails";

const Profile = (props) => {
  const [apiCallProcess, setApiCallProcess] = useState({
    action: false,
    loading: false,
  });
  const [pageLoading, setPageLoading] = useState(false);
  const [updateRequest, setUpdateRequest] = useState();

  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    setPageLoading(true);
    ApiCallGet(
      `${location?.pathname?.split("/")[1] === "admin" ? "admin" : "user"}/me`,
      props?.token,
      (res) => {
        if (res?.data?.success) {
          dispatch(onGetUser(res?.data));
          setUpdateRequest(res?.data?.data);
        } else {
          dispatch(logOut());
        }
        setPageLoading(false);
      }
    );
  }, [apiCallProcess?.action]);

  return (
    <div
      style={{ flex: 1 }}
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText="User Profile"
            Breadcrumb={[{ name: "Profile", navigate: "" }]}
          />
          <div className="row clearfix">
            <div className="col-lg-5 col-md-12">
              <ProfileHeaderCard
                user={props?.userdata}
                pageLoading={pageLoading}
              />
              <ProfileAddressCard
                address={props?.userdata?.address}
                token={props?.token}
                apiCallProcess={apiCallProcess}
                setApiCallProcess={setApiCallProcess}
                pageLoading={pageLoading}
              />
              {/* <FollowersCard HeaderText="Who to follow" /> */}
            </div>
            <div className="col-lg-7 col-md-12">
              <ProfileDetails
                request={updateRequest}
                setRequest={setUpdateRequest}
                token={props?.token}
                pageLoading={pageLoading}
                apiCallProcess={apiCallProcess}
                setApiCallProcess={setApiCallProcess}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ ioTReducer, getUserReducer, loginReducer }) => ({
  isSecuritySystem: ioTReducer.isSecuritySystem,
  userdata: getUserReducer.item,
  token: loginReducer?.token,
});

export default connect(mapStateToProps, {})(Profile);
