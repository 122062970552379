import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, Nav, Toast } from "react-bootstrap";
import { Link, Redirect, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  onPressThemeColor,
  onPressNotification,
  onPressSideMenuToggle,
  onPressSideMenuTab,
  tostMessageLoad,
  logOut,
  onRefreshToken,
} from "../../actions";
import Logo from "../../assets/images/Logo2_aakar.png";
import LogoWhite from "../../assets/images/Logo2_aakar.png";
import UserImage from "../../assets/images/user.png";
import Avatar4 from "../../assets/images/xs/avatar4.jpg";
import Avatar5 from "../../assets/images/xs/avatar5.jpg";
import Avatar2 from "../../assets/images/xs/avatar2.jpg";
import Avatar1 from "../../assets/images/xs/avatar1.jpg";
import Avatar3 from "../../assets/images/xs/avatar3.jpg";
import { ApiCall } from "../../ActionApiCall/ApiCall";

function AdminNavbarMenu2(props) {
  const dispatch = useDispatch();
  const location = useLocation();

  var activeKey = window.location.pathname;
  document.body.classList.add(props?.themeColor);
  activeKey = activeKey.split("/").pop();
  activeKey = activeKey ? "/" + activeKey : "/";

  const activeMenutabContainer = (id) => {
    var parents = document.getElementById("main-menu");
    var activeMenu = document.getElementById(id);

    for (let index = 0; index < parents.children.length; index++) {
      if (parents.children[index].id !== id) {
        parents.children[index].classList.remove("active");
        if (parents.children[index].children[1]) {
          parents.children[index].children[1].classList.remove("in");
        }
      }
    }
    setTimeout(() => {
      activeMenu.classList.toggle("active");
      if (activeMenu.children[1]) {
        activeMenu.children[1].classList.toggle("in");
      }
    }, 100);
  };

  const activeMenutabwhenNavigate = (active) => {
    if (active === "/dashboard") {
      activeMenutabContainer("dashboradContainer");
    } else if (
      active === "/unapprove-users" ||
      active === "/approve-users" ||
      active === "/new-users"
    ) {
      activeMenutabContainer("UserContainer");
    } else if (
      active === "/unapprove-inquiry" ||
      active === "/ongoing-inquiry"
    ) {
      activeMenutabContainer("InquiryContainer");
    } else if (
      active === "/processing-order" ||
      active === "/completed-order"
    ) {
      activeMenutabContainer("OrderContainer");
    } else if (active === "/design-library") {
      activeMenutabContainer("libraryContainer");
    }
  };

  const handleLogout = () => {
    dispatch(logOut());
  };

  useEffect(() => {
    tostMessageLoad(true);
    var res = window.location.pathname;
    res = res.split("/").pop();
    res = res ? "/" + res : "/";
    activeMenutabwhenNavigate(res);
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      if (props?.token) {
        ApiCall(
          "",
          `${
            location?.pathname?.split("/")[1] === "admin" ? "admin" : "user"
          }/refresh`,
          false,
          props?.token,
          (res) => {
            res.data.code === 200
              ? dispatch(onRefreshToken(res))
              : dispatch(logOut());
          }
        );
      }
    }, 40000);

    return () => {
      clearInterval(interval);
    };
  }, [props?.token]);

  return props?.role === 1 ? (
    <div>
      <nav className="navbar navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-btn">
            <button
              className="btn-toggle-offcanvas"
              onClick={() => {
                props.onPressSideMenuToggle();
              }}
            >
              <i className="lnr lnr-menu fa fa-bars"></i>
            </button>
          </div>

          <div className="navbar-brand">
            <Link to="dashboard">
              <img
                src={
                  document.body.classList.contains("full-dark")
                    ? LogoWhite
                    : Logo
                }
                alt="Aakar Logo"
                width={60}
                className="img-responsive logo"
              />
            </Link>
          </div>

          <div className="navbar-right">
            {/*  */}

            <div id="navbar-menu">
              <ul className="nav navbar-nav">
                <li style={{ cursor: "pointer" }}>
                  <span onClick={() => handleLogout()} className="icon-menu">
                    <i className="icon-login"></i>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div id="left-sidebar" className="sidebar" style={{ zIndex: 9 }}>
        <div className="sidebar-scroll">
          <div className="user-account">
            {/* <Link to={'profile'}> */}
            <img
              src={UserImage}
              className="rounded-circle user-photo"
              alt="User Profile Picture"
            /><br />
            <strong style={{ color: "#FEA87A" }}>Aakar Admin</strong>
            {/* </Link> */}
            <hr />
            {/* <ul className="row list-unstyled">
              <li className="col-4">
                <small>Sales</small>
                <h6>456</h6>
              </li>
              <li className="col-4">
                <small>Order</small>
                <h6>1350</h6>
              </li>
              <li className="col-4">
                <small>Rs.</small>
                <h6>2.13B</h6>
              </li>
            </ul> */}
          </div>
          {/* <ul className="nav nav-tabs">
            <li className="nav-item">
              <a
                className={
                  props?.sideMenuTab[0] ? "nav-link active" : "nav-link"
                }
                data-toggle="tab"
                onClick={() => {
                  props.onPressSideMenuTab(0);
                }}
              >
                Menu
              </a>
            </li> */}
          {/* <li className="nav-item">
              <a
                className={props?.sideMenuTab[1] ? "nav-link active" : "nav-link"}
                data-toggle="tab"
                onClick={() => {
                  props.onPressSideMenuTab(1);
                }}
              >
                <i className="icon-book-open"></i>
              </a>
            </li> */}
          {/* <li className="nav-item">
              <a
                className={
                  props?.sideMenuTab[2] ? "nav-link active" : "nav-link"
                }
                data-toggle="tab"
                onClick={() => {
                  props.onPressSideMenuTab(2);
                }}
              >
                <i className="icon-settings"></i>
              </a>
            </li> */}
          {/* <li className="nav-item">
              <a
                className={props?.sideMenuTab[3] ? "nav-link active" : "nav-link"}
                data-toggle="tab"
                onClick={() => {
                  props.onPressSideMenuTab(3);
                }}
              >
                <i className="icon-question"></i>
              </a>
            </li> */}
          {/* </ul> */}
          <div
            className="tab-content p-l-0 p-r-0"
            style={{ marginTop: "-2.5em" }}
          >
            <div
              className={
                props?.sideMenuTab[0] ? "tab-pane active show" : "tab-pane"
              }
              id="menu"
            >
              <Nav id="left-sidebar-nav" className="sidebar-nav">
                <ul id="main-menu" className="metismenu">
                  <li className="" id="dashboradContainer">
                    <Link to={"dashboard"}>
                      <i className="icon-home"></i>
                      <br /> <span>Dashboard</span>
                    </Link>
                  </li>

                  <li id="InquiryContainer" className="">
                    <a
                      href="#!"
                      className="has-arrow"
                      onClick={(e) => {
                        e.preventDefault();
                        activeMenutabContainer("InquiryContainer");
                      }}
                    >
                      <i className="icon-list"></i>
                      <br /> <span>Inquiry</span>
                    </a>
                    <ul className="collapse">
                      <li
                        className={
                          activeKey === "/ongoing-inquiry" ? "active" : ""
                        }
                      >
                        <Link to="ongoing-inquiry">Ongoing</Link>
                      </li>
                      <li
                        className={
                          activeKey === "/unapprove-inquiry" ? "active" : ""
                        }
                      >
                        <Link to="unapprove-inquiry">Rejected</Link>
                      </li>
                    </ul>
                  </li>
                  <li id="OrderContainer" className="">
                    <a
                      href="#!"
                      className="has-arrow"
                      onClick={(e) => {
                        e.preventDefault();
                        activeMenutabContainer("OrderContainer");
                      }}
                    >
                      <i className="icon-basket"></i>
                      <br /> <span>Order</span>
                    </a>
                    <ul className="collapse">
                      <li
                        className={
                          activeKey === "/processing-order" ? "active" : ""
                        }
                      >
                        <Link to="processing-order">Processing</Link>
                      </li>
                      <li
                        className={
                          activeKey === "/completed-order" ? "active" : ""
                        }
                      >
                        <Link to="completed-order">Completed</Link>
                      </li>
                    </ul>
                  </li>
                  <li id="UserContainer" className="">
                    <a
                      href="#!"
                      className="has-arrow"
                      onClick={(e) => {
                        e.preventDefault();
                        activeMenutabContainer("UserContainer");
                      }}
                    >
                      <i className="icon-users"></i>
                      <br /> <span>Manage Users</span>
                    </a>
                    <ul className="collapse">
                      <li
                        className={activeKey === "/new-users" ? "active" : ""}
                        onClick={() => {}}
                      >
                        <Link to="new-users">New Request</Link>
                      </li>
                      <li
                        className={
                          activeKey === "/approve-users" ? "active" : ""
                        }
                        onClick={() => {}}
                      >
                        <Link to="approve-users">Approve Users</Link>
                      </li>
                      <li
                        className={
                          activeKey === "/unapprove-users" ? "active" : ""
                        }
                        onClick={() => {}}
                      >
                        <Link to="unapprove-users">Unapprove Users</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="" id="libraryContainer">
                    <Link to={"design-library"}>
                      <i className="icon-home"></i>
                      <br /> <span>Design Library</span>
                    </Link>
                  </li>
                </ul>
              </Nav>
            </div>
            <div
              className={
                props?.sideMenuTab[1]
                  ? "tab-pane p-l-15 p-r-15 show active"
                  : "tab-pane p-l-15 p-r-15"
              }
              id="Chat"
            >
              <form>
                <div className="input-group m-b-20">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icon-magnifier"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
              </form>
              <ul className="right_chat list-unstyled">
                <li className="online">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar4} alt="" />
                      <div className="media-body">
                        <span className="name">Chris Fox</span>
                        <span className="message">Designer, Blogger</span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="online">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar5} alt="" />
                      <div className="media-body">
                        <span className="name">Joge Lucky</span>
                        <span className="message">Java Developer</span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="offline">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar2} alt="" />
                      <div className="media-body">
                        <span className="name">Isabella</span>
                        <span className="message">CEO, Thememakker</span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="offline">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar1} alt="" />
                      <div className="media-body">
                        <span className="name">Folisise Chosielie</span>
                        <span className="message">Art director, Movie Cut</span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="online">
                  <a>
                    <div className="media">
                      <img className="media-object " src={Avatar3} alt="" />
                      <div className="media-body">
                        <span className="name">Alexander</span>
                        <span className="message">Writter, Mag Editor</span>
                        <span className="badge badge-outline status"></span>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div
              className={
                props?.sideMenuTab[2]
                  ? "tab-pane p-l-15 p-r-15 show active"
                  : "tab-pane p-l-15 p-r-15"
              }
              id="setting"
            >
              <h6>Choose Mode</h6>
              <ul className="choose-skin list-unstyled">
                <li
                  data-theme="white"
                  className={
                    document.body.classList.contains("full-dark")
                      ? ""
                      : "active"
                  }
                  onClick={() => {
                    document.body.classList.remove("full-dark");
                  }}
                >
                  <div className="white"></div>
                  <span>Light</span>
                </li>
                <li
                  data-theme="black"
                  className={
                    document.body.classList.contains("full-dark")
                      ? "active"
                      : ""
                  }
                  onClick={() => {
                    // setState({ somethi: true });
                    document.body.classList.add("full-dark");
                  }}
                >
                  <div className="black"></div>
                  <span>Dark</span>
                </li>
              </ul>
              <hr />
              <h6>Choose Skin</h6>
              <ul className="choose-skin list-unstyled">
                <li
                  data-theme="purple"
                  className={
                    props?.themeColor === "theme-purple" ? "active" : ""
                  }
                >
                  <div
                    className="purple"
                    onClick={() => {
                      if (props?.themeColor !== "theme-purple") {
                        document.body.classList.remove(props?.themeColor);
                      }
                      props.onPressThemeColor("purple");
                    }}
                  ></div>
                  <span>Purple</span>
                </li>
                <li
                  data-theme="blue"
                  className={props?.themeColor === "theme-blue" ? "active" : ""}
                >
                  <div
                    className="blue"
                    onClick={() => {
                      if (props?.themeColor !== "theme-blue") {
                        document.body.classList.remove(props?.themeColor);
                      }
                      props.onPressThemeColor("blue");
                    }}
                  ></div>
                  <span>Blue</span>
                </li>
                <li
                  data-theme="cyan"
                  className={props?.themeColor === "theme-cyan" ? "active" : ""}
                >
                  <div
                    className="cyan"
                    onClick={() => {
                      if (props?.themeColor !== "theme-cyan") {
                        document.body.classList.remove(props?.themeColor);
                      }
                      props.onPressThemeColor("cyan");
                    }}
                  ></div>
                  <span>Cyan</span>
                </li>
                <li
                  data-theme="green"
                  className={
                    props?.themeColor === "theme-green" ? "active" : ""
                  }
                >
                  <div
                    className="green"
                    onClick={() => {
                      if (props?.themeColor !== "theme-green") {
                        document.body.classList.remove(props?.themeColor);
                      }
                      props.onPressThemeColor("green");
                    }}
                  ></div>
                  <span>Green</span>
                </li>
                <li
                  data-theme="orange"
                  className={
                    props?.themeColor === "theme-orange" ? "active" : ""
                  }
                >
                  <div
                    className="orange"
                    onClick={() => {
                      if (props?.themeColor !== "theme-orange") {
                        document.body.classList.remove(props?.themeColor);
                      }
                      props.onPressThemeColor("orange");
                    }}
                  ></div>
                  <span>Orange</span>
                </li>
                <li
                  data-theme="blush"
                  className={
                    props?.themeColor === "theme-blush" ? "active" : ""
                  }
                >
                  <div
                    className="blush"
                    onClick={() => {
                      if (props?.themeColor !== "theme-blush") {
                        document.body.classList.remove(props?.themeColor);
                      }
                      props.onPressThemeColor("blush");
                    }}
                  ></div>
                  <span>Blush</span>
                </li>
              </ul>
              <hr />
              <h6>General Settings</h6>
              <ul className="setting-list list-unstyled">
                <li>
                  <label className="fancy-checkbox">
                    <input type="checkbox" name="checkbox" />
                    <span>Report Panel Usag</span>
                  </label>
                </li>
                <li>
                  <label className="fancy-checkbox">
                    <input type="checkbox" name="checkbox" />
                    <span>Email Redirect</span>
                  </label>
                </li>
                <li>
                  <label className="fancy-checkbox">
                    <input type="checkbox" name="checkbox" />
                    <span>Notifications</span>
                  </label>
                </li>
                <li>
                  <label className="fancy-checkbox">
                    <input type="checkbox" name="checkbox" />
                    <span>Auto Updates</span>
                  </label>
                </li>
                <li>
                  <label className="fancy-checkbox">
                    <input type="checkbox" name="checkbox" />
                    <span>Offline</span>
                  </label>
                </li>
                <li>
                  <label className="fancy-checkbox">
                    <input type="checkbox" name="checkbox" />
                    <span>Location Permission</span>
                  </label>
                </li>
              </ul>
            </div>
            <div
              className={
                props?.sideMenuTab[3]
                  ? "tab-pane p-l-15 p-r-15 show active"
                  : "tab-pane p-l-15 p-r-15"
              }
              id="question"
            >
              <form>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="icon-magnifier"></i>
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search..."
                  />
                </div>
              </form>
              <ul className="list-unstyled question">
                <li className="menu-heading">HOW-TO</li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    How to Create Campaign
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Boost Your Sales
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Website Analytics
                  </a>
                </li>
                <li className="menu-heading">ACCOUNT</li>
                <li>
                  <a
                    href="registration"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Cearet New Account
                  </a>
                </li>
                <li>
                  <a
                    href="forgotpassword"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Change Password?
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Privacy &amp; Policy
                  </a>
                </li>
                <li className="menu-heading">BILLING</li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Payment info
                  </a>
                </li>
                <li>
                  <a
                    href="#!"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    Auto-Renewal
                  </a>
                </li>
                <li className="menu-button m-t-30">
                  <a
                    href="#!"
                    className="btn btn-primary"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <i className="icon-question"></i> Need Help?
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to="/login" />
  );
}

const mapStateToProps = ({ navigationReducer, loginReducer }) => {
  const { themeColor, toggleNotification, sideMenuTab, isToastMessage } =
    navigationReducer;
  const { role, token } = loginReducer;

  return {
    themeColor,
    toggleNotification,
    sideMenuTab,
    isToastMessage,
    role,
    token,
  };
};

export default connect(mapStateToProps, {
  onPressThemeColor,
  onPressNotification,
  onPressSideMenuToggle,
  onPressSideMenuTab,
  tostMessageLoad,
})(AdminNavbarMenu2);
