import React, { useEffect, useState } from "react";
import PageHeader from "../../../../components/PageHeader";
import { ApiCall, ApiCallGet } from "./../../../../ActionApiCall/ApiCall";
import { connect } from "react-redux";
import { ShowNotification } from "../../../../components/ShowNotification";
import { ShowSwal } from "../../../../components/ShowSwal";

function NewRequest(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState(false);
  const [toastMag, setToastMag] = useState({ show: false, msg: "", type: "" });

  const fetchUserData = () => {
    setLoading(true);
    ApiCallGet("admin/userList/0", props?.token, (result) => {
      // console.log(result);
      if (result?.data?.response === "success") {
        setData(result?.data?.data);
      }
      setLoading(false);
    });
  };

  const handleStatusUpdate = (status, id) => {
    const req = {
      status: status,
      id: id,
    };
    ShowSwal(
      "Are you sure?",
      "",
      "warning",
      status === 1 ? "Yes, Approve it!" : "Yes, Un-Approve it!",
      "Cancel",
      (result) => {
        if (result?.isConfirmed) {
          ApiCall(
            req,
            "admin/user-status-change",
            false,
            props?.token,
            (result) => {
              result?.data?.success
                ? setToastMag({
                    ...toastMag,
                    show: true,
                    msg: result?.data?.message
                      ? result?.data?.message
                      : "Successfully",
                    type: "success",
                  })
                : setToastMag({
                    ...toastMag,
                    show: true,
                    msg: result?.data?.message
                      ? result?.data?.message
                      : "Something Wrong",
                    type: "error",
                  });
              setAction(!action);
            }
          );
        }
      }
    );
  };

  useEffect(() => {
    fetchUserData();
  }, [action]);

  useEffect(() => {
    if (toastMag?.show) {
      setTimeout(() => {
        setToastMag({ ...toastMag, show: false });
      }, 4000);
    }
  }, [toastMag?.show]);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          {toastMag.show && (
            <ShowNotification
              title={toastMag?.msg}
              show={toastMag?.show}
              type={toastMag?.type}
            />
          )}
          <PageHeader
            HeaderText="New Request"
            Breadcrumb={[{ name: "New Request" }]}
          />
          <div className="col-lg-12">
            <div className="card">
              <div className="body table-responsive">
                <table className="table table-striped text-center">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Company Name</th>
                      <th>Email</th>
                      <th>Mobile No.</th>
                      <th>GST No.</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={7}>Loading.....</td>
                      </tr>
                    ) : data && data?.length > 0 ? (
                      data?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.name}</td>
                            <td>{item?.company_name}</td>
                            <td>{item?.email}</td>
                            <td>{item?.mobile_no}</td>
                            <td>{item?.gst_no}</td>
                            <td>
                              <i
                                onClick={() => handleStatusUpdate(1, item?.id)}
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                  color: "green",
                                }}
                                className="icon-check"
                              ></i>
                              &nbsp; &nbsp;
                              <i
                                onClick={() => handleStatusUpdate(2, item?.id)}
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                  color: "red",
                                }}
                                className="icon-close"
                              ></i>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={7}>Not Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state?.loginReducer?.token,
});

export default connect(mapStateToProps)(NewRequest);
