import React from "react";

const ShapeRange = () => {
  return (
    <div className="container mb-5">
      <div className="content-shape-range">
        <div className="row">
          <div className="col-4"></div>
          <div className="col-8">
            <div className="content">
              <h4>Our Shape Range</h4>
            </div>
            <div className="btn-shape">
              <button>Custom shape</button>
              <button>Standard shape</button>
              <button>Design Library</button>
              <button>My Designs</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShapeRange;
