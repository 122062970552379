import React, { useEffect, useState } from "react";
import PageHeader from "../../../../components/PageHeader";
import { ApiCallGet } from "../../../../ActionApiCall/ApiCall";
import { connect } from "react-redux";
import AakarModal from "../../../../components/AakarModal";
import { CustomRoute } from "../../../../lib/ApiRoute";
import { capitaliseFirstLetter } from "@fullcalendar/core";

function UserRejectedInquiry(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState({
    viewReason: false,
    viewNotes: false,
    msg: "",
  });

  const fetchInquiryData = () => {
    setLoading(true);
    ApiCallGet("user/inquiryList/2", props?.token, (result) => {
      if (result?.data?.success) {
        setData(result?.data?.data);
      }
      setLoading(false);
    });
  };

  const handleModalClose = () => {
    setModalShow({ viewReason: false, viewNotes: false, msg: "" });
  };
  const handleModalShow = (data) => {
    setModalShow({ viewReason: true, viewNotes: false, msg: data });
  };
  const handleNoteModalShow = (data) => {
    setModalShow({ viewReason: false, viewNotes: true, msg: data });
  };

  useEffect(() => {
    fetchInquiryData();
  }, []);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText="Rejected Inquiry"
            Breadcrumb={[{ name: "Rejected Inquiry" }]}
          />
          <AakarModal
            show={modalShow?.viewReason}
            handleClose={handleModalClose}
            headerTitle="Rejected Reason"
            view={true}
            body={modalShow?.msg}
          />
          <AakarModal
            show={modalShow?.viewNotes}
            handleClose={handleModalClose}
            headerTitle="Notes"
            view={true}
            body={modalShow?.msg}
          />
          <div className="col-lg-12">
            <div className="card">
              <div className="body table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Inquiry Code</th>
                      <th>Inquiry Date</th>
                      <th>Design Ref Num</th>
                      <th>Shape</th>
                      <th>Quantity</th>
                      <th>Type</th>
                      <th>Dimension</th>
                      <th>Details</th>
                      <th>Note</th>
                      <th>Reason</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan={11}>
                          Loading.....
                        </td>
                      </tr>
                    ) : data && data?.length > 0 ? (
                      data?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>AI{item?.id?.toString().padStart(7, "0")}</td>
                            <td>{item?.inquiry_date}</td>
                            <td>{item?.product?.user_ref_num}</td>
                            <td>
                              {item?.product?.images === null ? (
                                item?.product?.shape
                              ) : (
                                <a
                                  target="_blank"
                                  href={CustomRoute + item?.product?.images}
                                  className="btn btn-sm btn-success"
                                >
                                  View
                                </a>
                              )}
                            </td>
                            <td>{item?.qty}</td>
                            <td>
                              {capitaliseFirstLetter(
                                item?.product?.product_type
                              )}
                            </td>
                            <td>
                              {item?.product?.dimension !== null ? (
                                Object.entries(
                                  JSON.parse(item?.product?.dimension)
                                ).map(([key, value]) => (
                                  <div key={key}>
                                    <span>{key}</span>: <span>{value}mm</span>
                                  </div>
                                ))
                              ) : (
                                <p style={{ color: "red" }}>Not Applicable</p>
                              )}
                            </td>
                            <td>
                              Material type: {item?.material_type}
                              <br />
                              Material grade: {item?.material_grade}
                              <br />
                              Colour: {item?.colour}
                              <br />
                            </td>
                            <td>
                              <button
                                className="btn"
                                disabled={item?.remarks === null}
                                onClick={() =>
                                  handleNoteModalShow(item?.remarks)
                                }
                              >
                                <i
                                  style={{
                                    fontSize: "24px",
                                    color: "brown",
                                  }}
                                  className="icon-eye"
                                ></i>
                              </button>
                            </td>
                            <td>
                              <button
                                className="btn"
                                disabled={item?.unapprove_reason === null}
                                onClick={() =>
                                  handleModalShow(item?.unapprove_reason)
                                }
                              >
                                <i
                                  style={{
                                    fontSize: "24px",
                                    color: "red",
                                  }}
                                  className="icon-eye"
                                ></i>
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={11}>
                          Not Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state?.loginReducer?.token,
});

export default connect(mapStateToProps)(UserRejectedInquiry);
