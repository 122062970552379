import React, { useState, useEffect } from "react";
import { validate } from "../../../lib/joiHelper";
import updateSchema from "./updateSchema";
import { ShowSwal } from "../../../components/ShowSwal";
import { ApiCall } from "../../../ActionApiCall/ApiCall";
import { ShowNotification } from "../../../components/ShowNotification";

const ProfileDetails = ({
  request,
  setRequest,
  token,
  pageLoading,
  apiCallProcess,
  setApiCallProcess,
}) => {
  const [error, setError] = useState();
  const [toastMag, setToastMag] = useState({ show: false, msg: "", type: "" });
  const schema = updateSchema();

  const handleUpdateProfile = () => {
    const errorclone = validate(request, schema);
    if (errorclone) {
      setError(errorclone);
    } else {
      setError([]);
      ShowSwal(
        "Are you sure?",
        "Update Your Profile",
        "warning",
        "Yes, Update it!",
        "Cancel",
        (result) => {
          if (result?.isConfirmed) {
            setApiCallProcess({ ...apiCallProcess, loading: true });
            ApiCall(request, "user/update-profile", false, token, (res) => {
              if (res?.data?.success) {
                setToastMag({
                  ...toastMag,
                  show: true,
                  msg: res?.data?.message ? res?.data?.message : "Successfully",
                  type: "success",
                });
                setApiCallProcess({
                  ...apiCallProcess,
                  loading: false,
                  action: !apiCallProcess?.action,
                });
              } else {
                if (res?.response?.data?.error) {
                  Object.keys(res?.response?.data?.error).map((item, index) => {
                    console.log(
                      "error process",
                      res?.response?.data?.error[item][0]
                    );
                    setError((prev) => ({
                      ...prev,
                      [item]: res?.response?.data?.error[item][0],
                    }));
                  });
                  setApiCallProcess({ ...apiCallProcess, loading: false });
                }
                setToastMag({
                  ...toastMag,
                  show: true,
                  msg: res?.data?.message
                    ? res?.data?.message
                    : "Something Wrong",
                  type: "error",
                });
              }
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    if (toastMag?.show) {
      setTimeout(() => {
        setToastMag({ ...toastMag, show: false });
      }, 4000);
    }
  }, [toastMag?.show]);

  return (
    <div className="card">
      {toastMag.show && (
        <ShowNotification
          title={toastMag?.msg}
          show={toastMag?.show}
          type={toastMag?.type}
        />
      )}
      {pageLoading ? (
        <h5 className="text-center">Loading...</h5>
      ) : (
        <>
          <div className="header">
            <h2>Profile Details</h2>
          </div>
          <div className="body">
            <div className="form-group mb-2">
              <label>Name</label>
              <input
                required
                type="text"
                name="name"
                className="form-control"
                defaultValue={request?.name}
                onChange={(e) =>
                  setRequest({ ...request, [e.target.name]: e.target.value })
                }
              />
              <span style={{ color: "red" }}>{error?.name}</span>
            </div>
            <div className="form-group mb-2">
              <label>Company Name</label>
              <input
                required
                type="text"
                name="company_name"
                className="form-control"
                defaultValue={request?.company_name}
                onChange={(e) =>
                  setRequest({ ...request, [e.target.name]: e.target.value })
                }
              />
              <span style={{ color: "red" }}>{error?.company_name}</span>
            </div>
            <div className="form-group mb-2">
              <label>Email</label>
              <input
                required
                type="email"
                name="email"
                className="form-control"
                defaultValue={request?.email}
                onChange={(e) =>
                  setRequest({ ...request, [e.target.name]: e.target.value })
                }
              />
              <span style={{ color: "red" }}>{error?.email}</span>
            </div>
            <div className="form-group mb-2">
              <label>Mobile No.</label>
              <input
                required
                type="text"
                name="mobile_no"
                className="form-control"
                defaultValue={request?.mobile_no}
                onChange={(e) =>
                  setRequest({ ...request, [e.target.name]: e.target.value })
                }
              />
              <span style={{ color: "red" }}>{error?.mobile_no}</span>
            </div>
            <div className="form-group mb-2">
              <label>GST No.</label>
              <input
                required
                type="text"
                name="gst_no"
                className="form-control"
                defaultValue={request?.gst_no}
                onChange={(e) =>
                  setRequest({ ...request, [e.target.name]: e.target.value })
                }
              />
              <span style={{ color: "red" }}>{error?.gst_no}</span>
            </div>
            <div className="form-group mb-2">
              <button
                className="btn btn-info btn-sm"
                onClick={() => handleUpdateProfile()}
                disabled={apiCallProcess?.loading}
              >
                {apiCallProcess?.loading ? "Loading..." : "Update"}
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileDetails;
