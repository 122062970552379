import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/Logo2_aakar.png";
import "../scss/home.scss";

const HomeLayout = () => {
  return (
    <div className="container">
      <div className="home-header mt-4">
        <i className="fa fa-navicon"></i>
        <Link to={"/"}>
          <img
            src={Logo}
            alt="Aakar Logo"
            width={150}
            height={50}
            className="img-responsive logo"
          />
        </Link>
        <Link to={"/login"}>
          <i className="fa fa-user"></i>
        </Link>
      </div>
    </div>
  );
};

export default HomeLayout;
