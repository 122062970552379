import Joi from "joi";

export const reasonSchema = () =>
  Joi.object({
    reason: Joi.string()
      .required()
      //   .label("Username")
      .messages({
        "any.required": "Reason is required",
        "string.empty": "Reason is required",
        "string.base": "Invalid Reason",
      }),
    inqId: Joi.number()
      //   .label("Username")
      .messages({
        "number.base": "Invalid inqId",
      }),
  });
// export default reasonSchema;
export const approveSchema = () =>
  Joi.object({
    rate: Joi.number()
      .required()
      //   .label("Username")
      .messages({
        "any.required": "Rate is required",
        "number.empty": "Rate is required",
        "number.base": "Invalid Rate",
      }),
  });
