import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import LogoiCON from "../../../../assets/images/logo-icon.svg";

import PageHeader from "../../../../components/PageHeader";
import {
  COLOURS,
  MATERIAL_GRADE,
  MATERIAL_TYPE,
  NEAREST_SHAPE,
  ORDER_TYPE,
} from "../../../../lib/Constants";
import { ShowNotification } from "../../../../components/ShowNotification";
import { useHistory } from "react-router-dom";
import { ApiCall } from "../../../../ActionApiCall/ApiCall";
import { CustomRoute } from "../../../../lib/ApiRoute";

const CustomInquiry = (props) => {
  const { loadingPage, token } = props;

  const intialvalie = {
    product_type: "custom",
    order_type: 0,
    qty: "",
    material_type: "",
    material_grade: "",
    colour: "",
    images: "",
    remarks: "",
    refNum: "",
    nearest_shape: "",
  };

  const [formData, setFormData] = useState(intialvalie);
  const [toastMag, setToastMag] = useState({ show: false, msg: "", type: "" });
  const [selectedFile, setSelectedFile] = useState({
    file: false,
    img: false,
    data: null,
  });
  const history = useHistory();

  const handleColourChange = (e) => {
    setFormData({
      ...formData,
      [e?.target?.name]: e?.target?.value,
    });
  };

  const handleQtyChange = (e) => {
    setFormData({
      ...formData,
      [e?.target?.name]: e?.target?.value,
    });
  };

  const handleFileChnage = (e) => {
    setFormData({
      ...formData,
      [e?.target?.name]: e?.target?.files[0],
    });
    const file = e?.target?.files[0];
    if (file) {
      if (file.type === "application/pdf") {
        const reader = new FileReader();
        reader.onload = () => {
          setSelectedFile({ file: true, img: false, data: reader.result });
        };
        reader.readAsDataURL(file);
      } else {
        setSelectedFile({
          file: false,
          img: true,
          data: URL.createObjectURL(file),
        });
      }
    } else {
      setSelectedFile({ file: false, img: false, data: null });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const req = { ...formData };
    console.log(req);
    ApiCall(req, "user/products", false, token, (result) => {
      if (result?.data?.success) {
        setToastMag({
          ...toastMag,
          show: true,
          msg: result?.data?.message ? result?.data?.message : "Successfully",
          type: "success",
        });
        setTimeout(() => {
          // navigate("/user/dashboard", { replace: true })
          if (formData?.product_type === "standard") {
            history.push("/user/processing-order");
          } else {
            history.push("/user/ongoing-inquiry");
          }
        }, 500);
      } else {
        setToastMag({
          ...toastMag,
          show: true,
          msg: result?.data?.message
            ? result?.data?.message
            : "Something Wrong",
          type: "error",
        });
      }
    });
  };

  useEffect(() => {
    if (toastMag?.show) {
      setTimeout(() => {
        setToastMag({ ...toastMag, show: false });
      }, 3000);
    }
  }, [toastMag?.show]);

  if (loadingPage) {
    return (
      <div className="page-loader-wrapper">
        <div className="loader">
          <div className="m-t-30">
            <img src={LogoiCON} width="48" height="48" alt="Lucid" />
          </div>
          <p>Please wait...</p>
        </div>
      </div>
    );
  }
  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          {toastMag.show && (
            <ShowNotification
              title={toastMag?.msg}
              show={toastMag?.show}
              type={toastMag?.type}
            />
          )}
          <PageHeader
            HeaderText="New Inquiry - Complete Custom Shape"
            Breadcrumb={[{ name: "New Inquiry - Complete Custom Shape" }]}
          />
          <div className="">
            {/* <div className="header">
              <h2>Add New Inquiry</h2>
            </div> */}
            <div className="">
              <form
                onSubmit={(e) => handleFormSubmit(e)}
                method="post"
                encType="multipart/form-data"
              >
                <div className="row">
                  <div className="col-lg-9">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group mb-4">
                          <label>Design Reference Number</label>
                          <input
                            required
                            type="text"
                            name="refNum"
                            className="form-control"
                            value={formData?.refNum}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                [e?.target?.name]: e?.target?.value,
                              })
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-4">
                          <label>Select Nearest Shape</label>
                          <select
                            required
                            className="form-control"
                            value={formData?.nearest_shape}
                            name="nearest_shape"
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                [e?.target?.name]: e?.target?.value,
                              })
                            }
                          >
                            <option value="">Select..</option>
                            {NEAREST_SHAPE?.map((item, index) => {
                              return (
                                <option key={index} value={item?.toLowerCase()}>
                                  {item}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <div className="form-group mb-4">
                          <label>Upload your File</label>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <input
                              required
                              type="file"
                              name="images"
                              className="form-control"
                              style={{ width: "50%" }}
                              onChange={(e) => handleFileChnage(e)}
                            />
                            <span
                              className="ml-3"
                              style={{ marginBlockStart: "auto" }}
                            >
                              Don’t have the graph?{" "}
                              <a
                                href={`${CustomRoute}graph/1mm_graph_paper.pdf`}
                                target="_blank"
                              >
                                Download now{" "}
                                <i
                                  className="fa fa-download"
                                  style={{ fontSize: "20px" }}
                                ></i>
                              </a>
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="col-md-3 col-sm-4 text-center mb-4">
                        <label>Download Graph Paper</label>
                        <br />
                        <a
                          href={`${CustomRoute}graph/2mm_graph_paper.pdf`}
                          target="_blank"
                        >
                          <button
                            type="button"
                            className="btn btn-success btn-sm"
                            style={{ width: "50%" }}
                          >
                            <i className="fa fa-download"></i>
                          </button>
                        </a>
                      </div> */}
                      <div className="col-12 mb-3">
                        <hr
                          style={{
                            width: "100%",
                            border: "0.1px solid rgba(0,0,0,0.6)",
                            boxShadow: "0px 4px 2px rgba(0,0,0,0.3)",
                          }}
                        />
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-4">
                          <label>Material Type</label>
                          <br />
                          {MATERIAL_TYPE?.map((item, index) => {
                            return (
                              <label key={index} className="fancy-radio">
                                <input
                                  name="material_type"
                                  required=""
                                  type="radio"
                                  value={item?.toLowerCase()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      [e?.target?.name]: e?.target?.value,
                                    })
                                  }
                                />
                                <span>
                                  <i></i>
                                  {item}
                                </span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-4">
                          <label>Material Grade</label>
                          <br />
                          {MATERIAL_GRADE?.map((item, index) => {
                            return (
                              <label key={index} className="fancy-radio">
                                <input
                                  name="material_grade"
                                  required=""
                                  type="radio"
                                  value={item?.toLowerCase()}
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      [e?.target?.name]: e?.target?.value,
                                    })
                                  }
                                />
                                <span>
                                  <i></i>
                                  {item}
                                </span>
                              </label>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-4">
                          <label>Order Type</label>
                          <br />
                          {ORDER_TYPE?.map((item, index) => {
                            return (
                              <label key={index} className="fancy-radio">
                                <input
                                  name="order_type"
                                  required
                                  type="radio"
                                  value={item?.value}
                                  checked={
                                    parseInt(formData?.order_type) ===
                                    item?.value
                                  }
                                  onChange={(e) =>
                                    setFormData({
                                      ...formData,
                                      [e?.target?.name]: e?.target?.value,
                                    })
                                  }
                                />
                                <span>
                                  <i></i>
                                  {item?.label}
                                </span>
                              </label>
                            );
                          })}
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-group mb-4">
                          <label>Colour</label>
                          <div className="input-group mb-2">
                            <select
                              required
                              className="form-control"
                              value={formData?.colour}
                              name="colour"
                              onChange={(e) => handleColourChange(e)}
                            >
                              <option value="">Select..</option>
                              {COLOURS?.map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={item?.toLowerCase()}
                                  >
                                    {item}
                                  </option>
                                );
                              })}
                            </select>
                            <div
                              className="input-group-prepend"
                              style={{ width: "20%" }}
                            >
                              <div
                                className="input-group-text"
                                style={{
                                  background: formData?.colour,
                                  width: "100%",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group mb-4">
                          <label>Quantity</label>
                          <input
                            required
                            type="number"
                            name="qty"
                            className="form-control"
                            value={formData?.qty}
                            onChange={(e) => handleQtyChange(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div
                      className="card"
                      style={{
                        width: "95%",
                        marginLeft: "1em",
                        boxShadow: "-15px 14px 0px #122E4D",
                      }}
                    >
                      <div className="body">
                        <div className="row">
                          <div className="col-md-12 mb-3">
                            <div
                              style={{
                                width: "100%",
                                height: "14em",
                                background: "#FDFEFF",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                
                              }}
                            >
                              {!selectedFile?.data && (
                                <span className="p-2">Preview File</span>
                              )}
                              <br />
                              {selectedFile?.img && selectedFile?.data && (
                                <img
                                  src={selectedFile?.data}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                />
                              )}
                              {selectedFile?.file && selectedFile?.data && (
                                <embed
                                  src={selectedFile?.data}
                                  type="application/pdf"
                                  width="100%"
                                  height="100%"
                                />
                              )}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label>Note for Manufacturer</label>
                              <textarea
                                name="remarks"
                                className="form-control"
                                rows="3"
                                onChange={(e) =>
                                  setFormData({
                                    ...formData,
                                    [e?.target?.name]: e?.target?.value,
                                  })
                                }
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group mb-3">
                              <label></label>
                              <input
                                style={{
                                  width: "100%",
                                  background: "#19334F",
                                  color: "white",
                                }}
                                type="submit"
                                className="btn"
                                value={"Save & Submit Inquiry"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loadingPage: state.analyticalReducer.loadingPage,
  token: state?.loginReducer?.token,
});

export default connect(mapStateToProps)(CustomInquiry);
