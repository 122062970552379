export const onSetOrder = (result) => {
  return (dispatch) => {
    dispatch({
      type: "ON_SETORDER",
      inquiry_id: result?.inquiry_id,
      order_id: result?.order_id,
      orderdata: result?.orderdata,
    });
  };
};
