import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import navigationReducer from "./navigationReducer";
import analyticalReducer from "./analyticalReducer";
import demographicReducer from "./demographicReducer";
import ioTReducer from "./ioTReducer";
import mailInboxReducer from "./mailInboxReducer";
import UIElementsReducer from "./UIElementsReducer";
import setOrderReducer from "./setOrderReducer";
import getUserReducer from "./getUserReducer";

export default combineReducers({
  loginReducer,
  navigationReducer: navigationReducer,
  analyticalReducer: analyticalReducer,
  demographicReducer: demographicReducer,
  ioTReducer: ioTReducer,
  mailInboxReducer: mailInboxReducer,
  UIElementsReducer: UIElementsReducer,
  setOrderReducer: setOrderReducer,
  getUserReducer: getUserReducer
});
