import React, { useEffect, useState } from "react";
import PageHeader from "../../../../components/PageHeader";
import { ApiCallGet } from "../../../../ActionApiCall/ApiCall";
import { connect } from "react-redux";
import { CustomRoute } from "../../../../lib/ApiRoute";

const UserProcessing = (props) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  //   const [modalShow, setModalShow] = useState({ view: false, msg: "" });

  const fetchInquiryData = () => {
    setLoading(true);
    ApiCallGet("user/orderList/0", props?.token, (result) => {
      if (result?.data?.success) {
        setData(result?.data?.data);
      }
      setLoading(false);
    });
  };

  //   const handleModalClose = () => {
  //     setModalShow({ view: false, msg: "" });
  //   };
  //   const handleModalShow = (data) => {
  //     setModalShow({ view: true, msg: data });
  //   };

  useEffect(() => {
    fetchInquiryData();
  }, []);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText="Processing Order"
            Breadcrumb={[{ name: "Processing Order" }]}
          />
          {/* <AakarModal
              show={modalShow?.view}
              handleClose={handleModalClose}
              headerTitle="Rejected Reason"
              view={true}
              body={modalShow?.msg}
            /> */}
          <div className="col-lg-12">
            <div className="card">
              <div className="body table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Order Date</th>
                      <th>Product Type</th>
                      <th>User Details</th>
                      <th>Shape / Image</th>
                      <th>Qty</th>
                      <th>Dimension</th>
                      <th>Inquiry Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan={8}>
                          Loading.....
                        </td>
                      </tr>
                    ) : data && data?.length > 0 ? (
                      data?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>{item?.order_date}</td>
                            <td>{item?.inquiry?.product?.product_type}</td>
                            <td>
                              {item?.inquiry?.user?.name}
                              <br />
                              {item?.inquiry?.user?.mobile_no}
                              <br />
                              {item?.inquiry?.user?.email}
                            </td>
                            <td>
                              {item?.inquiry?.product?.images === null ? (
                                item?.inquiry?.product?.shape
                              ) : (
                                <a
                                  target="_blank"
                                  href={
                                    CustomRoute + item?.inquiry?.product?.images
                                  }
                                  className="btn btn-sm btn-success"
                                >
                                  View
                                </a>
                              )}
                            </td>
                            <td>{item?.inquiry?.qty}</td>
                            <td>
                              {item?.inquiry?.product?.dimension !== null ? (
                                Object.entries(
                                  JSON.parse(item?.inquiry?.product?.dimension)
                                ).map(([key, value]) => (
                                  <div key={key}>
                                    <span>{key}</span>: <span>{value}mm</span>
                                  </div>
                                ))
                              ) : (
                                <p style={{ color: "red" }}>Not Applicable</p>
                              )}
                            </td>
                            <td>
                              Material type: {item?.inquiry?.material_type}
                              <br />
                              Material grade: {item?.inquiry?.material_grade}
                              <br />
                              Colour: {item?.inquiry?.colour}
                              <br />
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={8}>
                          Not Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state?.loginReducer?.token,
});

export default connect(mapStateToProps)(UserProcessing);
