import React from "react";
import { connect } from "react-redux";

class ProductCard extends React.Component {
  render() {
    const { image, name, catagories } = this.props;
    return (
      <div className="card">
        <div className="body">
          {/* <div className="top-bar">
            <span>{rate}</span>
            <span className="float-right icon-heart"></span>
          </div> */}
          <img className="img-fluid" src={image} style={{height: "13em", width: "100%"}} />
          <div className="title text-center mt-2" style={{ textTransform: "capitalize", fontSize: "18px" }}>
            {name}
          </div>
          <button
            className="btn btn-sm mt-3"
            disabled
            style={{
              width: "100%",
              color: "white",
              background: "#19334F",
              fontSize: "12px",
              fontWeight: "500",
            }}
          >
            Place Order
          </button>
          {catagories && (
            <>
              <hr />
              <div className="footer">
                {catagories.map((data, i) => {
                  return (
                    <span key={i} className="badge badge-default" style={{ textTransform: "capitalize" }}>
                      {data}
                    </span>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ UIElementsReducer }) => ({});

export default connect(mapStateToProps, {})(ProductCard);
