import React from "react";
import image from "../../../assets/images/user.png";

const ProfileHeaderCard = ({ user, pageLoading }) => {
  return (
    <div className="card profile-header">
      <div className="body">
        {pageLoading ? (
          <h5 className="text-center">Loading...</h5>
        ) : (
          <>
            <div className="profile-image">
              <img alt="" className="rounded-circle" src={image} />
            </div>
            <div>
              <h4 className="m-b-0 mt-3">
                <strong>{user?.name}</strong>
              </h4>
              <span>{user?.company_name}</span>
            </div>
          </>
        )}

        {/* <div className="m-t-15">
          <button className="btn btn-primary mr-1">Follow</button>
          <button className="btn btn-outline-secondary">Message</button>
        </div> */}
      </div>
    </div>
  );
};

export default ProfileHeaderCard;
