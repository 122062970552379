import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/Logo2_aakar.png";
import "bootstrap/dist/css/bootstrap.min.css";
import { validate } from "../../../lib/joiHelper";
import signInSchema from "./signInSchema";
import { onLoggedin } from "../../../actions";
import axios from "axios";
import { ApiRoute } from "../../../lib/ApiRoute";
import { connect, useDispatch } from "react-redux";
import { Link, Redirect, useHistory, useLocation } from "react-router-dom";
import { ShowNotification } from "./../../../components/ShowNotification/index";
import { ShowSwal } from "./../../../components/ShowSwal/index";

function Login(props) {
  const [isLoad, setIsLoad] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const [errorMag, setErrorMag] = useState({
    show: false,
    msg: "",
    type: "error",
  });
  const fillObj = { email: "", password: "" };

  const [request, setRequest] = useState(fillObj);
  const [inputError, setInputError] = useState(fillObj);

  // const handleChange = (e) => {
  //   request[e.target.name] = e.target.value;
  //   setRequest({ ...request });
  // };

  const location = useLocation();

  const schema = signInSchema();

  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = () => {
    const errorclone = validate(request, schema);
    if (errorclone) {
      setInputError(errorclone);
    } else {
      setLoginLoading(true);
      // call server
      // setInputError("");
      // localStorage.setItem("authToken", "token");
      // navigate("/stores");
      axios
        .post(
          ApiRoute +
            `${
              location?.pathname?.split("/")[1] === "admin" ? "admin" : "user"
            }/login`,
          request
        )
        .then((response) =>
          response.data.code === 200
            ? (dispatch(onLoggedin(response)),
              setLoginLoading(false),
              setTimeout(() => {
                // navigate("/user/dashboard", { replace: true })
                history.push("/access");
              }, 500))
            : (ShowSwal(
                "Error",
                "Credentials not found",
                "error",
                "Ok",
                "Cancel",
                (result) => {}
              ),
              setLoginLoading(false))
        )
        .catch(
          (error) => (
            error?.response?.status === 500 && error?.response?.data?.error
              ? ShowSwal(
                  "Info",
                  error?.response?.data?.error,
                  "info",
                  "Ok",
                  "Cancel",
                  (result) => {}
                )
              : ShowSwal(
                  "Error",
                  "Credentials not found",
                  "error",
                  "Ok",
                  "Cancel",
                  (result) => {}
                ),
            setLoginLoading(false)
          )
        );
    }
  };

  useEffect(() => {
    setTimeout(() => setIsLoad(false), 500);
    document.body.classList.remove("theme-cyan");
    document.body.classList.remove("theme-purple");
    document.body.classList.remove("theme-blue");
    document.body.classList.remove("theme-green");
    document.body.classList.remove("theme-orange");
    document.body.classList.remove("theme-blush");
  }, []);

  useEffect(() => {
    if (errorMag?.show) {
      setTimeout(() => {
        setErrorMag({ ...errorMag, show: false });
      }, 5000);
    }
  }, [errorMag?.show]);

  return !props?.token ? (
    <div className="theme-cyan">
      <div
        className="page-loader-wrapper"
        style={{ display: isLoad ? "block" : "none" }}
      >
        <div className="loader">
          <div className="m-t-30">
            <img
              src={require("../../../assets/images/Logo2_aakar.png")}
              width="150"
              height="50"
              alt="Aakar"
            />
          </div>
          <p>Please wait...</p>
        </div>
      </div>
      {errorMag.show && (
        <ShowNotification
          title={errorMag.msg}
          show={errorMag.show}
          type={errorMag?.type}
          position="center"
        />
      )}
      <div className="hide-border">
        <div className="vertical-align-wrap">
          <div
            className={`vertical-align-middle auth-main auth-main-${
              location?.pathname?.split("/")[1] === "admin" ? "admin" : "user"
            }`}
          >
            <div className="auth-box">
              <div className="top">
                <Link to={"/"}>
                  <img
                    src={Logo}
                    alt="Aakar"
                    style={{ height: "50px", width: "200px", margin: "10px" }}
                  />
                </Link>
              </div>
              <div className="card">
                <div className="header">
                  <p className="lead">
                    {location?.pathname?.split("/")[1] === "admin"
                      ? "Admin Login"
                      : "Login to your account"}
                  </p>
                </div>
                <div className="body">
                  <div className="form-auth-small">
                    <div className="form-group">
                      <label className="control-label sr-only">Email</label>
                      <input
                        className="form-control"
                        id="signin-email"
                        placeholder="Email"
                        type="email"
                        name="email"
                        onChange={(e) =>
                          setRequest({ ...request, email: e.target.value })
                        }
                      />
                      <span style={{ color: "red" }}>{inputError?.email}</span>
                    </div>
                    <div className="form-group">
                      <label className="control-label sr-only">Password</label>
                      <input
                        className="form-control"
                        id="signin-password"
                        placeholder="Password"
                        type="password"
                        name="password"
                        onChange={(e) =>
                          setRequest({ ...request, password: e.target.value })
                        }
                      />
                      <span style={{ color: "red" }}>
                        {inputError?.password}
                      </span>
                    </div>
                    <div className="form-group clearfix">
                      <label className="fancy-checkbox element-left">
                        <input type="checkbox" />
                        <span>Remember me</span>
                      </label>
                    </div>
                    <button
                      className="btn btn-primary btn-lg btn-block"
                      onClick={handleSubmit}
                      disabled={loginLoading}
                    >
                      {!loginLoading ? "Login" : "Loading..."}
                    </button>
                    {location?.pathname?.split("/")[1] !== "admin" && (
                      <div className="bottom">
                        {/* <span className="helper-text m-b-10">
                        <i className="fa fa-lock"></i>{" "}
                        <a href={`/forgotpassword`}>Forgot password?</a>
                      </span> */}
                        <span>
                          Don't have an account?{" "}
                          <Link to="registration">Register</Link>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Redirect to={`${props?.role === 1 ? "/admin" : "/user"}/dashboard`} />
  );
}

const mapStateToProps = ({ loginReducer }) => ({
  role: loginReducer.role,
  token: loginReducer.token,
});

export default connect(mapStateToProps)(Login);
