import React from "react";
import PageHeader from "../../../components/PageHeader";

const UserSupport = () => {
  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader HeaderText="Support" Breadcrumb={[{ name: "Support" }]} />
        </div>
      </div>
    </div>
  );
};

export default UserSupport;
