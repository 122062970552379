import React, { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader";
import { useDispatch, connect } from "react-redux";
import { ApiCallGet } from "../../../ActionApiCall/ApiCall";
import { capitaliseFirstLetter } from "@fullcalendar/core";
import { CustomRoute } from "../../../lib/ApiRoute";
import ProductCard from "../../../components/Blog/ProductCard";

const UserLibrary = (props) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchInquiryData = () => {
    setLoading(true);
    ApiCallGet("user/shape-library", props?.token, (result) => {
      if (result?.data?.success) {
        setData(result?.data?.data);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchInquiryData();
  }, []);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader
            HeaderText="Design Library"
            Breadcrumb={[{ name: "Design Library" }]}
          />
          <div className="row">
            {loading ? (
              <div className="col-12 text-center mt-5">
                <h5>Loading.....</h5>
              </div>
            ) : data && data?.length > 0 ? (
              data?.map((item, index) => {
                return (
                  <div className="col-sm-6 col-md-3 col-6">
                    <ProductCard
                      image={CustomRoute + item?.images}
                      name={item?.ref_num}
                      catagories={[item?.shape]}
                    />
                  </div>
                );
              })
            ) : (
              <div className="col-12 text-center mt-5">
                <h5>Not Found</h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state?.loginReducer?.token,
});

export default connect(mapStateToProps)(UserLibrary);
