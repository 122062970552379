import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/aakar-svg/Logo_white.png";

const FooterComponents = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3 content-details">
            <Link to={"/"}>
              <img
                src={Logo}
                alt="Aakar Logo"
                width={200}
                height={60}
                className="img-responsive logo"
              />
            </Link>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Suspendisse egestas mauris. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Suspendisse egestas mauris.
            </p>
            <h6>&copy; Since 2023. All rights reserved.</h6>
          </div>
          <div className="col-md-6 link-content">
            <div className="links">
              <ul>
                <li>About</li>
                <li>
                  <Link to="refund-policy">Refund Policy</Link>
                </li>
                <li>What’s so special?</li>
                <li>Custom hook and loop</li>
                <li>Our shape range</li>
              </ul>
            </div>
          </div>
          <div className="col-md-3 contact-us">
            <div className="contact">
              <h6>Contact Us</h6>
              <p>
                <i className="fa fa-phone"></i>&nbsp; &nbsp; +91 9823 29365
              </p>
              <p>
                <i className="fa fa-phone"></i>&nbsp; &nbsp; +91 9823 29365
              </p>
              <p>
                <i className="fa fa-envelope"></i>&nbsp; &nbsp; info@aakar.com
              </p>
            </div>
            <div className="follow-us">
              <h6>Follow Us</h6>
              <div className="follow">
                <i className="fa fa-facebook"></i>
                <i className="fa fa-twitter"></i>
                <i className="fa fa-google-plus"></i>
                <i className="fa fa-linkedin"></i>
                <i className="fa fa-youtube"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponents;
