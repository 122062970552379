import React from "react";
import { Route } from "react-router-dom";

const NoAuthmiddleware = ({
  component: Component,
  layout: Layout,
  ...rest
}) => (
  <Route
    {...rest}
    exact
    render={(props) => {
      return (
        <>
          <Layout />
          <div>
            <Component {...props} />
          </div>
        </>
      );
    }}
  />
);

export default NoAuthmiddleware;
