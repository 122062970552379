import React from "react";
import { Button, Modal } from "react-bootstrap";

const AakarModal = ({
  show,
  handleClose,
  headerTitle,
  body,
  handleConfirm,
  loadState,
  view = false,
}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{headerTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          disabled={loadState?.loading}
          onClick={handleClose}
        >
          Close
        </Button>
        {!view && (
          <Button
            variant="primary"
            disabled={loadState?.loading}
            onClick={handleConfirm}
          >
            {loadState?.loading ? "Loading..." : "Confirm"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default AakarModal;
