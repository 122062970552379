import React from "react";

const CountPage = () => {
  return (
    <div className="container">
      <div className="count-div">
        <hr />
        <div className="counting">
            <div className="count-box">
                <h2>500+</h2>
                <p>Clients</p>
            </div>
            <div className="count-box">
                <h2>600+</h2>
                <p>Clients</p>
            </div>
            <div className="count-box">
                <h2>700+</h2>
                <p>Clients</p>
            </div>
            <div className="count-box">
                <h2>800+</h2>
                <p>Clients</p>
            </div>
        </div>
        <hr />
      </div>
    </div>
  );
};

export default CountPage;
