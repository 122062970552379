import React from "react";

import { IMG_WITH_TEXT } from "../../../lib/Constants";
import WhyUsPage from "../WhyUsPage";
import DownloadPage from "../DownloadPage";
import ShapeRange from "../ShapeRange";
import CountPage from "../CountPage";
import KnowMore from "../KnowMore";
import FooterComponents from "../FooterComponents";
import ExistingWork from "../ExistingWork";
import Testimonial from "../Testimonial";
import ShowDetails from "../ShowDetails";

const MainPage = () => {
  return (
    <>
      <div className="page-heading container">
        <p>Everything about</p>
        <span>AAKAR</span>
        <div className="banner-container">
          <div className="banner-card-bulk">
            {IMG_WITH_TEXT?.map((item, key) => {
              return (
                <div className="banner-card mb-5" key={key}>
                  <div className="banner-card-sub">
                    <img src={item?.imageSrc} width={80} height={80} />
                    <p>{item?.label}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <WhyUsPage />
      <DownloadPage />
      <ShapeRange />
      <ShowDetails />
      <CountPage />
      <KnowMore />
      <ExistingWork />
      <Testimonial />
      <FooterComponents />
      {/* <div className="page-why container">
        <img src={SkyMan1} width={250} height={600} />
      </div> */}
    </>
  );
};

export default MainPage;
