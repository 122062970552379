import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import Logo from "../../../assets/images/logo-white.svg";
import Logo from "../../../assets/images/Logo2_aakar.png";

import signUpSchema from "./signUpSchema";
import { validate } from "../../../lib/joiHelper";
import axios from "axios";
import { ApiRoute } from "../../../lib/ApiRoute";
import { Link, useHistory } from "react-router-dom";
import { ShowNotification } from "../../../components/ShowNotification";
import { ShowSwal } from "../../../components/ShowSwal";

function Register() {
  const fillObj = {
    email: "",
    number: "",
    company: "",
    password: "",
    confirm_password: "",
    name: "",
    gst_no: "",
  };
  const [isLoad, setIsLoad] = useState(true);
  const [loginLoading, setLoginLoading] = useState(false);
  const [request, setRequest] = useState(fillObj);
  const [inputError, setInputError] = useState(fillObj);
  const [errorMag, setErrorMag] = useState({
    show: false,
    msg: "",
    type: "error",
  });

  const schema = signUpSchema();
  const history = useHistory();

  const handleGSTNo = (e) => {
    setRequest({ ...request, gst_no: e.target.value });
    axios
      .get(`https://api.gst.gov.in/searchbypan/${e?.target?.value}`)
      .then((response) => {
        // const companyData = response.data;
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errorclone = validate(request, schema);
    if (errorclone) {
      setInputError(errorclone);
    } else {
      // call server
      setInputError("");
      // navigate("/stores");
      setLoginLoading(true);
      axios
        .post(ApiRoute + `user/register`, request)
        .then((response) =>
          response.data.success
            ? (setLoginLoading(false),
              // setErrorMag({
              //   show: true,
              //   msg: "Register Successfully...",
              //   type: "success",
              // }),
              setRequest(fillObj),
              ShowSwal(
                "",
                "Thank you for creating an account with Sky Aakar. We will review and activate it within 24-48hrs.",
                "success",
                "Ok",
                "Cancel",
                (result) => {
                  setTimeout(() => {
                    // navigate("/user/dashboard", { replace: true })
                    history.push("/login");
                  }, 500);
                }
              ))
            : (setErrorMag({
                show: true,
                msg: "Somthing Wrong...",
                type: "error",
              }),
              setLoginLoading(false))
        )
        .catch(
          (error) => (
            console.log(error?.response?.data?.error),
            error?.response?.data?.error &&
              Object.keys(error?.response?.data?.error).map((item, index) => {
                setInputError((prev) => ({
                  ...prev,
                  [item]: error?.response?.data?.error[item][0],
                }));
              }),
            setErrorMag({
              show: true,
              msg: "Somthing Wrong...",
              type: "error",
            }),
            setLoginLoading(false)
          )
        );
    }
  };

  useEffect(() => {
    setTimeout(() => setIsLoad(false), 500);
    document.body.classList.remove("theme-cyan");
    document.body.classList.remove("theme-purple");
    document.body.classList.remove("theme-blue");
    document.body.classList.remove("theme-green");
    document.body.classList.remove("theme-orange");
    document.body.classList.remove("theme-blush");
  }, []);

  useEffect(() => {
    if (errorMag?.show) {
      setTimeout(() => {
        setErrorMag({ ...errorMag, show: false });
      }, 5000);
    }
  }, [errorMag?.show]);

  return (
    <div className="theme-cyan">
      <div
        className="page-loader-wrapper"
        style={{ display: isLoad ? "block" : "none" }}
      >
        <div className="loader">
          <div className="m-t-30">
            <img
              src={require("../../../assets/images/Logo2_aakar.png")}
              width="150"
              height="50"
              alt="Aakar"
            />
          </div>
          <p>Please wait...</p>
        </div>
      </div>
      {errorMag.show && (
        <ShowNotification
          title={errorMag.msg}
          show={errorMag.show}
          type={errorMag?.type}
        />
      )}
      <div>
        <div className="vertical-align-wrap">
          <div className="vertical-align-middle auth-main auth-main-user">
            <div className="auth-box">
              <div className="top">
                <Link to={"/"}>
                  <img
                    src={Logo}
                    alt="Aakar"
                    // style={{ height: "40px", margin: "10px" }}
                    style={{ height: "50px", width: "200px", margin: "10px" }}
                  />
                </Link>
              </div>
              <div className="card">
                <div className="header">
                  <p className="lead">Create an account</p>
                </div>
                <div className="body">
                  <form
                    className="form-auth-small ng-untouched ng-pristine ng-valid"
                    onSubmit={handleSubmit}
                  >
                    <div className="form-group">
                      <label className="control-label sr-only">Name.</label>
                      <input
                        className="form-control"
                        id="signup-name"
                        placeholder="Your Name."
                        type="text"
                        value={request?.name}
                        onChange={(e) =>
                          setRequest({ ...request, name: e.target.value })
                        }
                      />
                      <span style={{ color: "red" }}>{inputError?.name}</span>
                    </div>
                    <div className="form-group">
                      <label className="control-label sr-only">Email</label>
                      <input
                        className="form-control"
                        id="signup-email"
                        placeholder="Your email"
                        type="email"
                        value={request?.email}
                        onChange={(e) =>
                          setRequest({ ...request, email: e.target.value })
                        }
                      />
                      <span style={{ color: "red" }}>{inputError?.email}</span>
                    </div>

                    <div className="form-group">
                      <label className="control-label sr-only">
                        Mobile No.
                      </label>
                      <input
                        className="form-control"
                        id="signup-number"
                        placeholder="Your Mobile No."
                        type="text"
                        min={10}
                        max={10}
                        value={request?.number}
                        onChange={(e) =>
                          setRequest({ ...request, number: e.target.value })
                        }
                      />
                      <span style={{ color: "red" }}>{inputError?.number}</span>
                    </div>
                    <div className="form-group">
                      <label className="control-label sr-only">GST No.</label>
                      <input
                        className="form-control"
                        id="signup-gst"
                        placeholder="Your GST Number"
                        type="text"
                        value={request?.gst_no}
                        onChange={(e) => handleGSTNo(e)}
                      />
                      <span style={{ color: "red" }}>{inputError?.gst_no}</span>
                    </div>
                    <div className="form-group">
                      <label className="control-label sr-only">
                        Company Name
                      </label>
                      <input
                        className="form-control"
                        id="signup-company"
                        placeholder="Your Company Name"
                        type="text"
                        value={request?.company}
                        onChange={(e) =>
                          setRequest({ ...request, company: e.target.value })
                        }
                      />
                      <span style={{ color: "red" }}>
                        {inputError?.company}
                      </span>
                    </div>
                    <div className="form-group">
                      <label className="control-label sr-only">Password</label>
                      <input
                        className="form-control"
                        id="signup-password"
                        placeholder="Password"
                        type="password"
                        value={request?.password}
                        onChange={(e) =>
                          setRequest({ ...request, password: e.target.value })
                        }
                      />
                      <span style={{ color: "red" }}>
                        {inputError?.password}
                      </span>
                    </div>
                    <div className="form-group">
                      <label className="control-label sr-only">
                        Confirm Password
                      </label>
                      <input
                        className="form-control"
                        id="signup-confirm-password"
                        placeholder="Confirm Password"
                        type="password"
                        value={request?.confirm_password}
                        onChange={(e) =>
                          setRequest({
                            ...request,
                            confirm_password: e.target.value,
                          })
                        }
                      />
                      <span style={{ color: "red" }}>
                        {inputError?.confirm_password}
                      </span>
                    </div>
                    <button
                      className="btn btn-primary btn-lg btn-block"
                      type="submit"
                      disabled={loginLoading}
                    >
                      {!loginLoading ? "REGISTER" : "Loading..."}
                    </button>
                    <div className="bottom">
                      <span className="helper-text">
                        Already have an account? <Link to="login">Login</Link>
                      </span>
                    </div>
                  </form>
                  {/* <div className="separator-linethrough">
                    <span>OR</span>
                  </div>
                  <button className="btn btn-signin-social">
                    <i className="fa fa-facebook-official facebook-color"></i>{" "}
                    Sign in with Facebook
                  </button>
                  <button className="btn btn-signin-social">
                    <i className="fa fa-twitter twitter-color"></i> Sign in with
                    Twitter
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
