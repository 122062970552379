import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PageHeader from "../../../../components/PageHeader";
import "./thankPage.scss";
import { connect, useDispatch } from "react-redux";
import { ApiCall, ApiCallGet } from "../../../../ActionApiCall/ApiCall";
import { onSetOrder } from "../../../../actions";

const WaitingPage = (props) => {
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [toastMag, setToastMag] = useState({ show: false, msg: "", type: "" });
  const dispatch = useDispatch();

  useEffect(() => {
    if (props?.orderdata) {
      ApiCall(
        props?.orderdata,
        "user/processing-order",
        false,
        props?.token,
        (res) => {
          if (res?.data?.success) {
            setToastMag({
              ...toastMag,
              show: true,
              msg: res?.data?.message ? res?.data?.message : "Successfully",
              type: "success",
            });
            if (res?.data?.order_id) {
              dispatch(onSetOrder({ order_id: res?.data?.order_id }));
            }
            setTimeout(() => {
              history.push("/user/order-success");
            }, 500);
          } else {
            setToastMag({
              ...toastMag,
              show: true,
              msg: res?.data?.message ? res?.data?.message : "Something Wrong",
              type: "error",
            });
          }
          setLoading(false);
        }
      );
    }
  }, []);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          <PageHeader HeaderText="Waiting" Breadcrumb={[{ name: "Waiting" }]} />
          {loading ? (
            <h4 className="text-center">Waiting Few Minutes...</h4>
          ) : (
            <h4 className="text-center">Payment Success</h4>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state?.loginReducer?.token,
  orderdata: state?.setOrderReducer?.orderdata,
});

export default connect(mapStateToProps)(WaitingPage);
