import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import PageHeader from "../../../components/PageHeader";
import { MODE_OF_PAYMENT } from "../../../lib/Constants";
import "./processorder.scss";
import { ApiCall, ApiCallGet } from "../../../ActionApiCall/ApiCall";
import { connect, useDispatch } from "react-redux";
import { capitaliseFirstLetter } from "@fullcalendar/core";
import AakarModal from "../../../components/AakarModal";
import SelectAddress from "./SelectAddress";
import AddNewAddress from "../../../components/AddNewAddress";
import { validate } from "../../../lib/joiHelper";
import addAddressSchema from "../../../components/AddNewAddress/addAddressSchema";
import { ShowNotification } from "../../../components/ShowNotification";
import orderSchema from "./orderSchema";
import { onSetOrder } from "../../../actions";

const UserProcessOrder = (props) => {
  const fillObjAddNew = {
    token: props?.token,
    address: "",
    city: "",
    state: "",
    country: "",
    pin_code: "",
  };

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({
    id: null,
    data: null,
  });
  const [viewAddress, setViewAddress] = useState(null);
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [openAddAddressModal, setOpenAddAddressModal] = useState(false);
  const [apiCallProcess, setApiCallProcess] = useState({
    action: false,
    loading: false,
  });
  const [request, setRequest] = useState({
    inquiry_id: props?.inquiry_id,
    mode_of_payment: "",
    address_id: "",
  });
  const [requestError, setRequestError] = useState({
    mode_of_payment: "",
    address_id: "",
  });
  const [toastMag, setToastMag] = useState({ show: false, msg: "", type: "" });
  const [requestForAdd, setRequestForAdd] = useState(fillObjAddNew);
  const [requestForAddError, setRequestForAddError] = useState(fillObjAddNew);
  const [addressData, setAddressData] = useState();
  const history = useHistory();

  const schema = addAddressSchema();
  const orderdataSchema = orderSchema();
  const dispatch = useDispatch();

  const fetchInquiryDataBtId = () => {
    if (props?.inquiry_id) {
      setLoading(true);
      ApiCallGet(
        `user/inquiryListById/${props?.inquiry_id}`,
        props?.token,
        (result) => {
          if (result?.data?.success) {
            setData(result?.data?.data);
          }
          setLoading(false);
        }
      );
    }
  };

  const handleOpenAddressModal = () => {
    setOpenAddressModal(true);
  };

  const handleAddAddressModal = () => {
    setOpenAddAddressModal(true);
  };

  const handleModalClose = () => {
    openAddressModal && !openAddAddressModal && setOpenAddressModal(false);
    openAddAddressModal && setOpenAddAddressModal(false);
    setRequestForAddError(fillObjAddNew);
    setRequestForAdd(fillObjAddNew);
  };

  const handleAddModalConfirm = () => {
    const errorclone = validate(requestForAdd, schema);
    if (errorclone) {
      setRequestForAddError(errorclone);
    } else {
      setApiCallProcess({ ...apiCallProcess, loading: true });
      // console.log(requestForAdd);
      ApiCall(
        requestForAdd,
        "user/add-new-address",
        false,
        props?.token,
        (res) => {
          if (res?.data?.success) {
            setToastMag({
              ...toastMag,
              show: true,
              msg: res?.data?.message ? res?.data?.message : "Successfully",
              type: "success",
            });
            setOpenAddAddressModal(false);
          } else {
            setToastMag({
              ...toastMag,
              show: true,
              msg: res?.data?.message ? res?.data?.message : "Something Wrong",
              type: "error",
            });
          }
          setApiCallProcess({
            ...apiCallProcess,
            loading: false,
            action: !apiCallProcess?.action,
          });
        }
      );
    }
  };

  const handleSelectAddressConfirm = () => {
    setRequest({ ...request, address_id: selectedAddress?.id });
    setViewAddress(selectedAddress?.data);
    selectedAddress?.id && setOpenAddressModal(false);
  };

  const RedirectCCAvenue = (data) => {
    // console.log(data);
    const form = document.createElement("form");
    form.method = "post";
    form.action = data.url;
    form.name = "redirect";

    // Create hidden input fields for encrypted data and access code
    const encRequestInput = document.createElement("input");
    encRequestInput.type = "hidden";
    encRequestInput.name = "encRequest";
    encRequestInput.value = data.encrypted_data;

    const accessCodeInput = document.createElement("input");
    accessCodeInput.type = "hidden";
    accessCodeInput.name = "access_code";
    accessCodeInput.value = data.access_code;

    // Append the hidden inputs to the form
    form.appendChild(encRequestInput);
    form.appendChild(accessCodeInput);

    // Submit the form programmatically
    document.body.appendChild(form);
    form.submit();
  };

  const handleConfirmOrder = () => {
    setRequestError(null);
    const errorclone = validate(request, orderdataSchema);
    if (errorclone) {
      setRequestError(errorclone);
    } else {
      // console.log(request);
      if (request?.mode_of_payment === "pay online") {
        ApiCall(
          request,
          "user/processing-order-with-payment",
          false,
          props?.token,
          (res) => {
            if (res?.data?.success) {
              // console.log(res.data);
              RedirectCCAvenue(res.data);
            }
          }
        );
        
      } else {
        ApiCall(
          request,
          "user/processing-order",
          false,
          props?.token,
          (res) => {
            if (res?.data?.success) {
              setToastMag({
                ...toastMag,
                show: true,
                msg: res?.data?.message ? res?.data?.message : "Successfully",
                type: "success",
              });
              if (res?.data?.order_id) {
                dispatch(onSetOrder({ order_id: res?.data?.order_id }));
              }
              setTimeout(() => {
                history.push("/user/order-success");
              }, 500);
            } else {
              setToastMag({
                ...toastMag,
                show: true,
                msg: res?.data?.message
                  ? res?.data?.message
                  : "Something Wrong",
                type: "error",
              });
            }
          }
        );
      }
    }
  };

  const getAddressData = () => {
    ApiCallGet(`user/user-address-list`, props?.token, (result) => {
      if (result?.data?.success) {
        setAddressData(result?.data?.data);
      }
    });
  };

  useEffect(() => {
    getAddressData();
  }, [apiCallProcess?.action]);

  useEffect(() => {
    if (toastMag?.show) {
      setTimeout(() => {
        setToastMag({ ...toastMag, show: false });
      }, 4000);
    }
  }, [toastMag?.show]);

  useEffect(() => {
    fetchInquiryDataBtId();
  }, []);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          {toastMag.show && (
            <ShowNotification
              title={toastMag?.msg}
              show={toastMag?.show}
              type={toastMag?.type}
            />
          )}
          <PageHeader
            HeaderText="Process Order"
            Breadcrumb={[{ name: "Process Order" }]}
          />
          <AakarModal
            show={openAddressModal}
            handleClose={handleModalClose}
            headerTitle={
              <div style={{ display: "flex" }}>
                <h5 className="mt-2">Select Address</h5> &nbsp;&nbsp;
                <button
                  className="btn btn-outline-info btn-sm"
                  onClick={() => handleAddAddressModal()}
                >
                  Add New Address
                </button>
              </div>
            }
            loadState={apiCallProcess?.action}
            body={
              <SelectAddress
                action={apiCallProcess?.action}
                state={selectedAddress}
                setState={setSelectedAddress}
                addressData={addressData}
              />
            }
            handleConfirm={handleSelectAddressConfirm}
          />
          <AakarModal
            show={openAddAddressModal}
            handleClose={handleModalClose}
            headerTitle="Add New Aaddress"
            body={
              <AddNewAddress
                state={requestForAdd}
                setState={setRequestForAdd}
                inputError={requestForAddError}
              />
            }
            loadState={apiCallProcess}
            handleConfirm={handleAddModalConfirm}
          />
          {loading ? (
            <h4 className="text-center">Loading...</h4>
          ) : data ? (
            <div className="row">
              <div
                className="col-lg-12 col-md-12 col-sm-12"
                style={{ color: "red", fontSize: "18px", textAlign: "center" }}
              >
                <p>**Don't Refresh This Page</p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Product Details</h4>
                  </div>
                  <div className="card-body">
                    <table className="table table-borderless process-order-table">
                      <tr>
                        <th>Design Ref Num: </th>
                        <td>{data?.product?.user_ref_num}</td>
                      </tr>
                      <tr>
                        <th>Shape: </th>
                        <td>{data?.product?.shape}</td>
                      </tr>
                      <tr>
                        <th>Type: </th>
                        <td>
                          {data?.product?.product_type &&
                            capitaliseFirstLetter(data?.product?.product_type)}
                        </td>
                      </tr>
                      <tr>
                        <th>Quantity: </th>
                        <td>{data?.qty}</td>
                      </tr>
                      <tr>
                        <th>Material Grade: </th>
                        <td>
                          {data?.material_grade &&
                            capitaliseFirstLetter(data?.material_grade)}
                        </td>
                      </tr>
                      <tr>
                        <th>Material Type: </th>
                        <td>
                          {data?.material_type &&
                            capitaliseFirstLetter(data?.material_type)}
                        </td>
                      </tr>
                      <tr>
                        <th>Order Type: </th>
                        <td>{data?.order_type === 0 ? "Sample" : "Full"}</td>
                      </tr>
                      <tr>
                        <th>Note: </th>
                        <td>{data?.remarks}</td>
                      </tr>
                      <tr>
                        <th>Dimension: </th>
                        {data?.product?.dimension !== null &&
                          Object.entries(
                            JSON.parse(data?.product?.dimension)
                          ).map(([key, value]) => (
                            <div key={key}>
                              <span>{capitaliseFirstLetter(key)}</span>:{" "}
                              <span>{value}mm</span>
                            </div>
                          ))}
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Summury</h4>
                  </div>
                  <div className="card-body">
                    {" "}
                    <table className="table table-borderless">
                      <tr>
                        <th style={{ width: "40%" }}>Price (Per Piece): </th>
                        <th style={{ fontSize: "20px", paddingTop: "0.4em" }}>
                          &#8377; {data?.rate}
                        </th>
                      </tr>
                      <tr>
                        <th>Total Price: </th>
                        <th style={{ fontSize: "20px", paddingTop: "0.4em" }}>
                          &#8377; {data?.total_price}
                        </th>
                      </tr>
                      <tr>
                        <th>Mode Of Payment:</th>
                        <th>
                          {MODE_OF_PAYMENT?.map((item, index) => {
                            return (
                              <label key={index} className="fancy-radio">
                                <input
                                  name="mode_of_payment"
                                  required=""
                                  type="radio"
                                  value={item?.toLowerCase()}
                                  checked={
                                    request?.mode_of_payment ===
                                    item?.toLowerCase()
                                  }
                                  onChange={(e) =>
                                    setRequest({
                                      ...request,
                                      [e?.target?.name]: e?.target?.value,
                                    })
                                  }
                                />
                                <span>
                                  <i></i>
                                  {item}
                                </span>
                              </label>
                            );
                          })}
                          <br />
                          <span style={{ color: "red" }}>
                            {requestError?.mode_of_payment}
                          </span>
                        </th>
                      </tr>
                      <tr>
                        <th colSpan={2}>
                          <span>Select Address:</span>
                          <br />
                          <div
                            style={{
                              border: "2px solid #49c5b6",
                              borderTopLeftRadius: "20%",
                              borderBottomRightRadius: "20%",
                              padding: "1em",
                              marginTop: "0.5em",
                              cursor: "pointer",
                            }}
                            onClick={() => handleOpenAddressModal()}
                          >
                            {viewAddress ? viewAddress : "Select Address..."}
                          </div>
                          <span style={{ color: "red" }}>
                            {requestError?.address_id}
                          </span>
                        </th>
                      </tr>
                    </table>
                    <button
                      className="btn"
                      onClick={handleConfirmOrder}
                      style={{ background: "#19334f", color: "white" }}
                    >
                      Place Order
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                fontWeight: "bold",
                fontSize: "18px",
                textAlign: "center",
                marginTop: "10em",
              }}
            >
              <p>
                Please <Link to="ongoing-inquiry">Back</Link> in Ongoing Inquiry
                and can Re-process
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  token: state?.loginReducer?.token,
  inquiry_id: state?.setOrderReducer?.inquiry_id,
});

export default connect(mapStateToProps)(UserProcessOrder);
