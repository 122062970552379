import React from "react";
import StarRating from "../../../components/StarRating";
import User from "../../../assets/images/aakar-svg/Ellipse 3.png";

const Testimonial = () => {
  return (
    <div className="container">
      <div className="row testimonial">
        <div className="col-md-6 testimonial-content">
          <div className="testimonial-card">
            <div className="testimonial-sub-card">
              <div className="star-rating">
                <StarRating value={5} />
              </div>
              <hr />
              <div className="testimonial-text">
                <p>
                  ‘‘Loved the experience and the work they do, must try for all!
                  Great work.’’
                </p>
                <h6>~Mr. Mehta</h6>
              </div>
            </div>
          </div>
          <div className="testimonial-img">
            <img src={User} />
          </div>
        </div>
        <div className="col-md-6 content-details">
          <h2>Hear From Clients</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
            egestas mauris.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
