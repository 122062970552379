import React, { useEffect, useState } from "react";
import PageHeader from "../../../../components/PageHeader";
import { ApiCall, ApiCallGet } from "../../../../ActionApiCall/ApiCall";
import { connect } from "react-redux";
import { ShowNotification } from "../../../../components/ShowNotification";
import { Modal } from "react-bootstrap";
import AakarModal from "../../../../components/AakarModal";
import { validate } from "../../../../lib/joiHelper";
import { approveSchema, reasonSchema } from "./schema";
import { ShowSwal } from "../../../../components/ShowSwal";
import { CustomRoute } from "../../../../lib/ApiRoute";
import { capitaliseFirstLetter } from "@fullcalendar/core";

function NewInquiry(props) {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [toastMag, setToastMag] = useState({ show: false, msg: "", type: "" });
  const [modalShow, setModalShow] = useState(false);
  const [approveModalShow, setApproveModalShow] = useState(false);
  const [modalId, setModalId] = useState({
    inqId: "",
    reason: "",
  });
  const [modalNotesShow, setModalNotesShow] = useState({
    view: false,
    msg: "",
  });

  const [action, setAction] = useState({
    loading: false,
    action: false,
  });
  const [inputError, setInputError] = useState({ reason: "" });
  const [approveRequest, setApproveRequest] = useState({
    rate: "",
    approve_notes: "",
    inqId: "",
  });
  const [approveError, setApproveError] = useState({ rate: "" });

  const schema = reasonSchema();
  const apSchema = approveSchema();

  const fetchInquiryData = () => {
    setLoading(true);
    ApiCallGet("admin/inquiryList/0", props?.token, (result) => {
      // console.log(result);
      if (result?.data?.response === "success") {
        setData(result?.data?.data);
      }
      setLoading(false);
    });
  };

  const handleApproveModalShow = (id) => {
    setApproveRequest({ ...approveRequest, inqId: id });
    setApproveModalShow(true);
  };

  const handleStatusUpdate = () => {
    const errorclone = validate(approveRequest, apSchema);
    if (errorclone) {
      setApproveError(errorclone);
    } else {
      setApproveError(null);
      ShowSwal(
        "Are you sure?",
        "",
        "warning",
        "Yes, Approve it!",
        "Cancel",
        (result) => {
          if (result?.isConfirmed) {
            setAction({ ...action, loading: true });
            ApiCall(
              approveRequest,
              "admin/inquiry/approve",
              false,
              props?.token,
              (res) => {
                res?.data?.success
                  ? setToastMag({
                      ...toastMag,
                      show: true,
                      msg: res?.data?.message
                        ? res?.data?.message
                        : "Successfully",
                      type: "success",
                    })
                  : setToastMag({
                      ...toastMag,
                      show: true,
                      msg: res?.data?.message
                        ? res?.data?.message
                        : "Something Wrong",
                      type: "error",
                    });
                setApproveModalShow(false);
                setAction({ action: !action?.action, loading: false });
              }
            );
          }
        }
      );
    }
  };

  const handleModalClose = () => {
    modalShow && setModalId({ ...modalId, inqId: "" });
    modalShow && setModalShow(false);
    approveModalShow &&
      setApproveRequest({ rate: "", approve_notes: "", inqId: "" });
    approveModalShow && setApproveModalShow(false);
    modalNotesShow?.view && setModalNotesShow({ view: false, msg: "" });
  };
  const handleModalShow = (id) => {
    setModalId({ ...modalId, inqId: id });
    setModalShow(true);
  };

  const handleNoteModalShow = (data) => {
    setModalNotesShow({ view: true, msg: data });
  };

  const handleModalConfirm = () => {
    const errorclone = validate(modalId, schema);
    if (errorclone) {
      setInputError(errorclone);
    } else {
      setInputError(null);
      ShowSwal(
        "Are you sure?",
        "",
        "warning",
        "Yes, Unapprove it!",
        "Cancel",
        (result) => {
          if (result?.isConfirmed) {
            setAction({ ...action, loading: true });
            ApiCall(
              modalId,
              "admin/inquiry/unapprove",
              false,
              props?.token,
              (res) => {
                res?.data?.success
                  ? setToastMag({
                      ...toastMag,
                      show: true,
                      msg: res?.data?.message
                        ? res?.data?.message
                        : "Successfully",
                      type: "success",
                    })
                  : setToastMag({
                      ...toastMag,
                      show: true,
                      msg: res?.data?.message
                        ? res?.data?.message
                        : "Something Wrong",
                      type: "error",
                    });
                setModalShow(false);
                setAction({ action: !action?.action, loading: false });
              }
            );
          }
        }
      );
    }
  };

  useEffect(() => {
    fetchInquiryData();
  }, [action?.action]);

  useEffect(() => {
    if (toastMag?.show) {
      setTimeout(() => {
        setToastMag({ ...toastMag, show: false });
      }, 4000);
    }
  }, [toastMag?.show]);

  return (
    <div
      onClick={() => {
        document.body.classList.remove("offcanvas-active");
      }}
    >
      <div>
        <div className="container-fluid">
          {toastMag.show && (
            <ShowNotification
              title={toastMag?.msg}
              show={toastMag?.show}
              type={toastMag?.type}
            />
          )}
          <PageHeader
            HeaderText="New Inquiry"
            Breadcrumb={[{ name: "New Inquiry" }]}
          />
          <AakarModal
            show={modalNotesShow?.view}
            handleClose={handleModalClose}
            headerTitle="Notes"
            view={true}
            body={modalNotesShow?.msg}
          />
          <AakarModal
            show={modalShow}
            handleClose={handleModalClose}
            headerTitle="Reason For Unapprove"
            loadState={action}
            body={
              <>
                <textarea
                  className="form-control"
                  rows="5"
                  cols="5"
                  onChange={(e) =>
                    setModalId({ ...modalId, reason: e?.target?.value })
                  }
                  name="reason"
                  id="reason"
                  style={{ width: "100%" }}
                ></textarea>
                <span style={{ color: "red" }}>{inputError?.reason}</span>
              </>
            }
            handleConfirm={handleModalConfirm}
          />
          <AakarModal
            show={approveModalShow}
            handleClose={handleModalClose}
            headerTitle="Approve Inquiry"
            loadState={action}
            body={
              <>
                <label>Rate ( )</label>
                <input
                  className="form-control"
                  name="rate"
                  id="rate"
                  onChange={(e) =>
                    setApproveRequest({
                      ...approveRequest,
                      rate: e?.target?.value,
                    })
                  }
                />
                <span style={{ color: "red" }}>{approveError?.rate}</span>
                <br />
                <label className="mt-2">Notes</label>
                <textarea
                  className="form-control"
                  rows="5"
                  cols="5"
                  onChange={(e) =>
                    setApproveRequest({
                      ...approveRequest,
                      approve_notes: e?.target?.value,
                    })
                  }
                  name="approve_notes"
                  id="approve_notes"
                  style={{ width: "100%" }}
                ></textarea>
                <span style={{ color: "red" }}>
                  {approveError?.approve_notes}
                </span>
              </>
            }
            handleConfirm={handleStatusUpdate}
          />
          <div className="col-lg-12">
            <div className="card">
              <div className="body table-responsive">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Inquiry Code</th>
                      <th>Inquiry Date</th>
                      <th>Design Ref Num</th>
                      <th>Shape</th>
                      <th>Quantity</th>
                      <th>Type</th>
                      <th>Dimension</th>
                      <th>Details</th>
                      <th>Note</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td className="text-center" colSpan={11}>
                          Loading.....
                        </td>
                      </tr>
                    ) : data && data?.length > 0 ? (
                      data?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <th scope="row">{index + 1}</th>
                            <td>AI{item?.id?.toString().padStart(7, "0")}</td>
                            <td>{item?.inquiry_date}</td>
                            <td>{item?.product?.user_ref_num}</td>
                            <td>
                              {item?.product?.images === null ? (
                                item?.product?.shape
                              ) : (
                                <a
                                  target="_blank"
                                  href={CustomRoute + item?.product?.images}
                                  className="btn btn-sm btn-success"
                                >
                                  View
                                </a>
                              )}
                            </td>
                            <td>{item?.qty}</td>
                            <td>
                              {capitaliseFirstLetter(
                                item?.product?.product_type
                              )}
                            </td>
                            <td>
                              {item?.product?.dimension !== null ? (
                                Object.entries(
                                  JSON.parse(item?.product?.dimension)
                                ).map(([key, value]) => (
                                  <div key={key}>
                                    <span>{key}</span>: <span>{value}mm</span>
                                  </div>
                                ))
                              ) : (
                                <p style={{ color: "red" }}>Not Applicable</p>
                              )}
                            </td>
                            <td>
                              Material type: {item?.material_type}
                              <br />
                              Material grade: {item?.material_grade}
                              <br />
                              Colour: {item?.colour}
                              <br />
                            </td>
                            <td>
                              <button
                                className="btn"
                                disabled={item?.remarks === null}
                                onClick={() =>
                                  handleNoteModalShow(item?.remarks)
                                }
                              >
                                <i
                                  style={{
                                    fontSize: "24px",
                                    color: "brown",
                                  }}
                                  className="icon-eye"
                                ></i>
                              </button>
                            </td>
                            <td>
                              <i
                                onClick={() => handleApproveModalShow(item?.id)}
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                  color: "green",
                                }}
                                className="icon-check"
                              ></i>
                              &nbsp; &nbsp;
                              <i
                                onClick={() => handleModalShow(item?.id)}
                                style={{
                                  fontSize: "20px",
                                  cursor: "pointer",
                                  color: "red",
                                }}
                                className="icon-close"
                              ></i>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="text-center" colSpan={11}>
                          Not Found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  token: state?.loginReducer?.token,
});

export default connect(mapStateToProps)(NewInquiry);
