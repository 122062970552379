import React from "react";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import dashboard from "./screens/Dashbord/Dashbord";
import demographic from "./screens/Dashbord/Demographic";
import ioT from "./screens/Dashbord/IoT";
import appInbox from "./screens/App/Inbox";
import appChat from "./screens/App/Chat";
import appCalendar from "./screens/App/Calendar";
import appContact from "./screens/App/Contact";
import appTaskbar from "./screens/App/Taskbar";
import filemanagerdashboard from "./screens/FileManager/Dashboard";
import filedocuments from "./screens/FileManager/Documents";
import filemedia from "./screens/FileManager/Media";
import fileimages from "./screens/FileManager/Images";
import blognewPost from "./screens/Blog/NewPost";
import blogdetails from "./screens/Blog/BlogDetails";
import bloglist from "./screens/Blog/BlogList";
import uitypography from "./screens/UIElements/Typography";
import uitabs from "./screens/UIElements/Tabs";
import uibuttons from "./screens/UIElements/Button";
import bootstrapui from "./screens/UIElements/BootstrapUI";
import uiicons from "./screens/UIElements/Icons";
import uinotifications from "./screens/UIElements/Notifications";
import uicolors from "./screens/UIElements/Colors";
import uilistgroup from "./screens/UIElements/ListGroup";
import uimediaobject from "./screens/UIElements/MediaObject";
import uimodal from "./screens/UIElements/Modals";
import uiprogressbar from "./screens/UIElements/ProgressBar";
import widgetsdata from "./screens/Widgets/Data";
import widgetsweather from "./screens/Widgets/Weather";
import widgetsblog from "./screens/Widgets/Blog";
import widgetsecommers from "./screens/Widgets/ECommers";
import registration from "./screens/Auth/Registration";
import lockscreen from "./screens/Auth/Lockscreen";
import forgotpassword from "./screens/Auth/ForgotPassword";
import page404 from "./screens/Auth/Page404";
import page403 from "./screens/Auth/Page403";
import page500 from "./screens/Auth/Page500";
import page503 from "./screens/Auth/Page503";
import blankpage from "./screens/Pages/BlankPage";
import profilev1page from "./screens/Pages/ProfileV1";
import profilev2page from "./screens/Pages/ProfileV2";
import imagegalleryprofile from "./screens/Pages/ImageGallery";
import timeline from "./screens/Pages/TimeLine";
import pricing from "./screens/Pages/Pricing";
import invoices from "./screens/Pages/Invoices";
import invoicesv2 from "./screens/Pages/InvoicesV2";
import searchresult from "./screens/Pages/SearchResults";
import helperclass from "./screens/Pages/HelperClass";
import teamsboard from "./screens/Pages/TeamsBoard";
import projectslist from "./screens/Pages/ProjectsList";
import maintanance from "./screens/Pages/Maintanance";
import testimonials from "./screens/Pages/Testimonials";
import faqs from "./screens/Pages/Faqs";
import formvalidation from "./screens/Forms/FormValidation";
import basicelements from "./screens/Forms/BasicElements";
import tablenormal from "./screens/Tables/TableNormal";
import echart from "./screens/Charts/Echart";
import leafletmap from "./screens/Maps/GoogleMaps";
import Login from "./screens/Auth/Login/index";
import Register from "./screens/Auth/Register";
import AdminAuthmiddleware from "./lib/AdminAuthmiddleware";
import UserAuthmiddleware from "./lib/NoAuthmiddleware";
import Access from "./lib/Access";
import AdminDashbord from "./screens/Admin/Dashboard/AdminDashbord";
import { adminRoutes, homeRoutes, userRoutes } from "./allRoutes";
import AdminNavbarMenu2 from "./screens/Admin/AdminNavbarMenu2";
import UserNavbarMenu2 from "./screens/User/UserNavbarMenu2";
import UserPayment from "./screens/User/UserPayment";
import PaymentForm1 from "./components/Blog/PaymentForm1";
import PaymentFormWrapper from "./components/PaymentFormWrapper";
import NoAuthmiddleware from "./lib/NoAuthmiddleware";
import HomeLayout from "./screens/Home/HomeLayout";
import RefundPolicy from "./screens/Home/RefundPolicy";
import ScrollToTop from "./components/ScrollToTop";

window.__DEV__ = true;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoad: true,
    };
  }
  render() {
    var res = window.location.pathname;
    var baseUrl = process.env.PUBLIC_URL;
    baseUrl = baseUrl.split("/");
    res = res.split("/").pop();
    res = res ? res : "/";
    const activeKey1 = res;

    return (
      <div id="wrapper">
        <ScrollToTop>
          {activeKey1 === "" ||
          activeKey1 === "login" ||
          activeKey1 === "registration" ||
          activeKey1 === "lockscreen" ||
          activeKey1 === "forgotpassword" ||
          activeKey1 === "page404" ||
          activeKey1 === "payment-process" ||
          activeKey1 === "access" ? (
            // activeKey1 === "page500" ||
            // activeKey1 === "page503" ||
            // activeKey1 === "maintanance"
            <Switch>
              {/* <Route exact path={`${process.env.PUBLIC_URL}`} component={Login} /> */}

              <Route exact path={`/login`} component={Login} />
              <Route
                exact
                path={`/forgotpassword`}
                component={forgotpassword}
              />
              <Route exact path={`/page404`} component={page404} />
              <Route exact path={`/access`} component={Access} />
              <Route exact path={`/admin/login`} component={Login} />
              {/* <Route exact path={`/page403`} component={page403} /> */}
              {/* <Route exact path={`/page500`} component={page500} /> */}
              {/* <Route exact path={`/page503`} component={page503} /> */}
              <Route exact path={`/registration`} component={Register} />
              <Route
                exact
                path={`/payment-process`}
                component={PaymentFormWrapper}
              />
              {/* <Route exact path={`registration`} component={Register} /> */}
              {/* <Route exact path={`/lockscreen`} component={lockscreen} /> */}
              {/* <Route exact path={`/maintanance`} component={maintanance} /> */}
            </Switch>
          ) : (
            <>
              <Switch>
                <Route exact path={`/refund-policy`} component={RefundPolicy} />
                {adminRoutes?.map((routes, idx) => {
                  return (
                    <AdminAuthmiddleware
                      path={routes.path}
                      layout={AdminNavbarMenu2}
                      // history={this.props.history}
                      // activeKey={activeKey1}
                      component={routes.component}
                      key={idx}
                      isAuthProtected={true}
                    />
                  );
                })}
                {userRoutes?.map((routes, idx) => {
                  return (
                    <AdminAuthmiddleware
                      path={routes.path}
                      layout={UserNavbarMenu2}
                      // history={this.props.history}
                      // activeKey={activeKey1}
                      component={routes.component}
                      key={idx}
                      isAuthProtected={true}
                    />
                  );
                })}
                {homeRoutes?.map((routes, idx) => {
                  return (
                    <NoAuthmiddleware
                      path={routes.path}
                      layout={HomeLayout}
                      // history={this.props.history}
                      // activeKey={activeKey1}
                      component={routes.component}
                      key={idx}
                    />
                  );
                })}
              </Switch>
              {/* <AdminAuthmiddleware>
              <AdminNavbarMenu
                history={this.props.history}
                activeKey={activeKey1}
              />
              <div id="main-content">
                <Switch>
                  <Route
                    exact
                    path={`/admin/dashboard`}
                    component={AdminDashbord}
                  /> */}
              {/* <Route exact path={`/demographic`} component={demographic} />
                  <Route exact path={`/ioT`} component={ioT} />
                  <Route exact path={`/appinbox`} component={appInbox} />
                  <Route exact path={`/appchat`} component={appChat} />
                  <Route exact path={`/appcalendar`} component={appCalendar} />
                  <Route exact path={`/appcontact`} component={appContact} />
                  <Route exact path={`/apptaskbar`} component={appTaskbar} />
                  <Route
                    exact
                    path={`/filemanagerdashboard`}
                    component={filemanagerdashboard}
                  />
                  <Route
                    exact
                    path={`/filedocuments`}
                    component={filedocuments}
                  />
                  <Route exact path={`/filemedia`} component={filemedia} />
                  <Route exact path={`/fileimages`} component={fileimages} />
                  <Route exact path={`/blognewpost`} component={blognewPost} />
                  <Route exact path={`/blogdetails`} component={blogdetails} />
                  <Route exact path={`/bloglist`} component={bloglist} />
                  <Route
                    exact
                    path={`/uitypography`}
                    component={uitypography}
                  />
                  <Route exact path={`/uitabs`} component={uitabs} />
                  <Route exact path={`/bootstrapui`} component={bootstrapui} />
                  <Route exact path={`/uiicons`} component={uiicons} />
                  <Route
                    exact
                    path={`/uinotifications`}
                    component={uinotifications}
                  />
                  <Route exact path={`/uicolors`} component={uicolors} />
                  <Route exact path={`/uilistgroup`} component={uilistgroup} />
                  <Route
                    exact
                    path={`/uimediaobject`}
                    component={uimediaobject}
                  />
                  <Route exact path={`/uimodal`} component={uimodal} />
                  <Route exact path={`/uibuttons`} component={uibuttons} />
                  <Route
                    exact
                    path={`/uiprogressbar`}
                    component={uiprogressbar}
                  />
                  <Route exact path={`/widgetsdata`} component={widgetsdata} />
                  <Route
                    exact
                    path={`/widgetsweather`}
                    component={widgetsweather}
                  />
                  <Route exact path={`/widgetsblog`} component={widgetsblog} />
                  <Route
                    exact
                    path={`/widgetsecommers`}
                    component={widgetsecommers}
                  />
                  <Route exact path={`/blankpage`} component={blankpage} />
                  <Route
                    exact
                    path={`/profilev1page`}
                    component={profilev1page}
                  />
                  <Route
                    exact
                    path={`/profilev2page`}
                    component={profilev2page}
                  />
                  <Route
                    exact
                    path={`/imagegalleryprofile`}
                    component={imagegalleryprofile}
                  />
                  <Route exact path={`/timeline`} component={timeline} />
                  <Route exact path={`/pricing`} component={pricing} />
                  <Route exact path={`/invoices`} component={invoices} />
                  <Route exact path={`/invoicesv2`} component={invoicesv2} />
                  <Route
                    exact
                    path={`/searchresult`}
                    component={searchresult}
                  />
                  <Route exact path={`/helperclass`} component={helperclass} />
                  <Route exact path={`/teamsboard`} component={teamsboard} />
                  <Route
                    exact
                    path={`/projectslist`}
                    component={projectslist}
                  />
                  <Route
                    exact
                    path={`/testimonials`}
                    component={testimonials}
                  />
                  <Route exact path={`/faqs`} component={faqs} />
                  <Route
                    exact
                    path={`/formvalidation`}
                    component={formvalidation}
                  />
                  <Route
                    exact
                    path={`/basicelements`}
                    component={basicelements}
                  />
                  <Route exact path={`/tablenormal`} component={tablenormal} />
                  <Route exact path={`/echart`} component={echart} />
                  <Route exact path={`/leafletmap`} component={leafletmap} /> */}
              {/* </Route> */}
              {/* </Switch>
              </div>
            </AdminAuthmiddleware> */}
              {/* <UserAuthmiddleware>
              <UserNavbarMenu
                history={this.props.history}
                activeKey={activeKey1}
              />
              <div id="main-content">
                <Switch>
                  <Route exact path={`/user/dashboard`} component={dashboard} />
                </Switch>
              </div>
            </UserAuthmiddleware> */}
            </>
          )}
        </ScrollToTop>
      </div>
    );
  }
}

const mapStateToProps = ({ loginReducer }) => ({
  role: loginReducer.role,
});

export default withRouter(connect(mapStateToProps, {})(App));
