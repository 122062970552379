import Circle from "../assets/images/Shape-img/circle.jpg";
import DCut from "../assets/images/Shape-img/D_cut.jpg";
import CornerCut from "../assets/images/Shape-img/corner_cut.jpg";
import Rectangle from "../assets/images/Shape-img/rectangle.jpg";
import RoundCorner from "../assets/images/Shape-img/round_corner.jpg";
import PremiumImg from "../assets/images/aakar-svg/premium.png"
import EditSettingsImg from "../assets/images/aakar-svg/edit-settings.png"
import FlagForIndiaImg from "../assets/images/aakar-svg/flag-for-india.png"
import BuildingImg from "../assets/images/aakar-svg/mingcute_building.png"

export const NEAREST_SHAPE = [
  "Round",
  "Square",
  "Triangle",
  "Rectangle",
  "Others",
];

export const SHAPE_WITH_IMAGE = [
  { label: "Round", imageSrc: Circle },
  { label: "Rectangle", imageSrc: Rectangle },
  { label: "Rectangle with Rounded-Corner", imageSrc: RoundCorner },
  { label: "Rectangle with Rounded-Edge", imageSrc: CornerCut },
  { label: "D-shape", imageSrc: DCut },
];

export const IMG_WITH_TEXT = [
  { label: "Premium", imageSrc: PremiumImg },
  { label: "Customisable", imageSrc: EditSettingsImg },
  { label: "Industrial", imageSrc: BuildingImg },
  { label: "Made in India", imageSrc: FlagForIndiaImg },
];

export const COLOURS = [
  "White",
  "Black",
  "Grey",
  "Beige",
  "Red",
  "Green",
  "Blue",
  "Orange",
  "Yellow",
];

export const MATERIAL_TYPE = ["Hook", "Loop"];

export const MATERIAL_GRADE = ["Magic", "Magna"];

export const ORDER_TYPE = [
  { value: 0, label: "Sample" },
  { value: 1, label: "Full" },
];

export const MODE_OF_PAYMENT = ["Cash", "Pay Later", "Pay Online"];

export const WIDTH_OPTIONS = [16, 20, 25, 50, 75, 100];

export const DIA_OPTIONS = [15, 20, 25, 32, 38, 50, 75, 100];

export const ORDER_STATUS = [
  { value: 0, label: "Pending" },
  { value: 1, label: "Completed" },
];
