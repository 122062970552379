import Swal from "sweetalert2";

export const ShowSwal = (
  title,
  text,
  icon,
  buttonText = "Submit",
  denyButtonText = "Cancel",
  callback
) => {
  Swal.fire({
    title: title,
    text: text,
    icon: icon,
    showCancelButton: false,
    showDenyButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: buttonText,
    denyButtonText: denyButtonText,
  }).then((result) => {
    callback(result);
  });
};
